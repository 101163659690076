import { APIURL } from "@/utils/authConfig";
import { processData } from '@/utils/processData.js';
import { set } from "lodash-es";

export default {
  namespaced: true,
  state: {
    searchResult: [],
    searchLoading: true,
    searchQuery: "",
    nameCheckResult : [],
    nameCheckLoading :true
  },
  mutations: {
    resetSearchResult(state){
      state.searchResult = [];
    },
    setSearchLoading(state, value) {
      state.searchLoading = value;
    },
    setSearchResult(state, value) {
      state.searchResult = value;
    },
    setSearchQuery(state, value) {
      state.searchQuery = value;
    },
    setNameCheckResult(state, value) {
      state.nameCheckResult = value;
    },
    setNameCheckLoading(state, value) {
      state.nameCheckLoading = value;
    }
  },
  actions: {
    //the dict {commit, dispatch, state,rootState } is context
    async getSearchResult({ commit, dispatch, state, rootState }, searchQuery) {
      commit("setSearchLoading", true); //start skeleton loading
      try {
        let sortQuery = "";
        if (rootState.filter.sortValue) {
          sortQuery = `&sort_by=${rootState.filter.sortValue}`;
        }//issue here because by default it will be date not relevancy

        var myHeaders = new Headers();
        let token = rootState.auth.token; // NEW!
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        var body = rootState.filter.filterArray;

        var requestOptions = { headers: myHeaders,body: JSON.stringify({"filters":body}) || "{}",method: "POST"};

        const res = await fetch(
          `${APIURL}/search-table?query=${searchQuery}${sortQuery}`,
          requestOptions
        );

        if (res.status == 401) {
          throw new Error(response.status);
        }
        let data = await res.json();

        //add this year age
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        processData(data, currentYear);
        commit("resetSearchResult");
        commit("setSearchResult", data); //store the data in global var
        dispatch("track/trackSearch", searchQuery, { root: true });
        commit("setSearchLoading", false); // stop skeleton Loading
        commit("data/setActiveID", "", { root: true }); //reset active ID
      } catch (error) {
        //if there is any error like expired token,
        console.warn(error);
        commit("setSearchResult", {}); //
      }
    },

    async getNameCheck({ commit, rootState }, nameCheckQuery) {
      commit("setNameCheckLoading", true); //
      var myHeaders = new Headers();
        let token = rootState.auth.token; //
        myHeaders.append("Authorization", `Bearer ${token}`); //
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = { headers: myHeaders,method: "POST"};

        const res = await fetch(
          `${APIURL}/search-name?query=${nameCheckQuery}`,
          requestOptions
        );

        if (res.status == 401) {
          throw new Error(response.status);
        }
        let data = await res.json();

        // make the backend return name and id so that can click and go there
        commit("setNameCheckResult", data); //store the data
        commit("setNameCheckLoading", false); 
     }
  }
};
