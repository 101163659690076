import { v4 as uuidv4 } from "uuid";
import { APIURL } from "@/utils/authConfig";
import { cloneDeep } from "lodash-es";

export default {
  setData(event, varToChange) {
    this[varToChange] = event;
    // console.log(event)
    //COMPILE WHEN SUBMIT , KEEP CHECKBOX SEPERATE
  },
  setDataCheckbox(event, varToChange) {
    this[varToChange[0]] = event["answer"];
    if (event["otherBool"] == true) {
      this[varToChange[1]] = event["other"];
    } else {
      this[varToChange[1]] = "";
    }
  },
  buttonHandlingAfterSubmit() {
    this.showProcessButton = false;
    this.showLoading = true;
    this.isBtnDisable = true;
  },
  async redirectHome() {
    //refresh data after submission
    this.$store.commit("data/resetNDList");
    let adminRole = this.$store.state.auth.adminRole;
    if (adminRole){
        //getAllData
        await this.$store.dispatch("data/getAllData", this.$store.state.auth.token)
    }else{
        await this.$store.dispatch("data/getPaginatedData", [
            this.$store.state.auth.token,
            0,
            20
          ]);
    }
    

    // need to show loading after this, cos if internet slow
    this.$router.push({
      name: "home",
    });
    this.isBtnDisable = false;
  },

  async onDelete() {
    this.isBtnDisable = true;
    this.showDeleteButton = false;
    let bodyToDel = {
      id: this.id,
      pk: "0",
      date: this.formatDateNow, //this is edited date  always will be overwritten
    };

    await this.$store.dispatch("auth/getAuth");
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${this.$store.state.auth.token}`); // Must pass token for every call
    myHeaders.append("username", this.$store.state.auth.username); // send data to backend
    // console.log(JSON.stringify(body))
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(bodyToDel),
    };

    let res = await fetch(`${APIURL}/delete-person`, requestOptions);

    alert("This entry will be deleted.");

    this.isBtnDisable = false;
    this.showLoading = true;
    this.showDeleteButton = true;
    this.redirectHome();
  },

  async onSubmit() {
    this.showProcessButton = true;
    this.isBtnDisable = true;
    const skillItems = [];
    if (this.skillOutput.length > 0) {
      this.skillOutput.forEach((el, id) => {
        skillItems.push({ id: id, value: el });
      });
    }
    if (this.skillOutputOther.length > 0) {
      this.skillOutputOther.forEach((el, id) => {
        skillItems.push(el);
      });
    }
    // Remove duplicates based on the 'value' property
    console.log(skillItems)

    const uniqueSkillItems = Array.from(new Map(skillItems.map(item => [item.value, item])).values());
    skillItems.length = 0;
    skillItems.push(...uniqueSkillItems);

    // form validation , error handling
    this.error = [];
    if (!this.candidateName) {
      this.error.push("Candidate Name");
    }
    if (!this.honorificOutput) {
      this.error.push("Honorific");
    }
    // if (!this.birthYear || isNaN(this.birthYear)) {
    //   //need to check for number only
    //   this.error.push("Year Of Birth");
    // }
    // will allow NA
    if (!this.genderOutput) {
      this.error.push("Gender");
    }
    if (!this.nationalityOutput) {
      this.error.push("Nationality");
    }
    if (!this.ethnicityOutput && this.branchNationalityOutput) {
      this.error.push("Ethnicity");
    } //when edit mode certain data will be null
    if (!this.countryOutput.length) {
      this.error.push("Country Of Residence");
    }
    // Optional
    // if (!this.comments.length) {
    //     this.error.push("Team's Comment")
    // }
    if (!this.currentNDOutput.length) {
      this.error.push("Current Khazanah ND?");
    }

    if (!this.kAlumniOutput.length) {
      this.error.push("Is Khazanah Alumni?");
    }

    //branch
    //multi value
    if (this.branchCurrentNDOutput) {
      if (!this.ndCompanyOutput) {
        this.error.push("Name of Investee Co or SPV");
      } else {
        this.ndCompanyOutput.forEach((el) => {
          if (!el["value"]) {
            this.error.push("Name of Investee Co or SPV");
          }
        });
      }
    }
    //branch
    // multi value
    if (this.branchCurrentNDOutput) {
      if (!this.assetClassOutput) {
        this.error.push("Asset Class");
      } else {
        this.assetClassOutput.forEach((el) => {
          if (!el["value"]) {
            this.error.push("Asset Class");
          }
        });
      }
    }

    //multivalue
    if (!this.previousEmploymentOutput.length) {
      this.error.push("Previous Employment");
    } else {
      this.previousEmploymentOutput.forEach((el) => {
        if (!el["value"]) {
          this.error.push("Previous Employment");
        }
      });
    }

    //multivalue
    if (!this.previousIndustriesOutput) {
      this.error.push("Previous Industries");
    } else {
      this.previousIndustriesOutput.forEach((el) => {
        if (!el["value"]) {
          this.error.push("Previous Industries");
        }
      });
    }

    //multivalue need to try?
    if (!skillItems) {
      this.error.push("Candidate Skill Set");
    } else {
      skillItems.forEach((el) => {
        if (!el["value"]) {
          this.error.push("Candidate Skill Set");
        }
      });
    }
    this.error = [...new Set(this.error)];

    if (this.error.length > 0) {
      //
      let errorString = "Form Invalid.Please recheck the fields below:";
      this.error.forEach((el) => {
        errorString = errorString.concat(`\n${el}`);
      });
      alert(errorString);
      this.showProcessButton = false;
      this.isBtnDisable = false;
    } else {
      ////

      if (this.formMode != "Edit") {
        //if form mode is create new
        this.id = uuidv4();
        this.reviewed = false;
        this.archived = false;
        this.isRecommended = false;
        //need to edit later
      } else {
        if (this.archived == "") {
          this.archived = false;
        }
      }

      if (this.birthYear == "") {
        this.birthYear = null;
      }
      const body = {
        id: this.id,
        name: this.candidateName, //string
        honorific: this.honorificOutput, //string
        birth_year: this.birthYear, //number or null
        gender: this.genderOutput, //string
        nationality: this.nationalityOutput, //string
        ethnicity: this.ethnicityOutput, //string
        residence_country: this.countryOutput, //string
        comments: this.comments, //string
        url: this.url, //string
        k_alumni: this.kAlumniOutput,
        // below all array of objects that contain strings as value
        k_nd: [
          {
            id: 0,
            value: this.currentNDOutput,
          },
        ],
        nd_company: this.ndCompanyOutput,
        asset_class: this.assetClassOutput,
        education: this.educationOutput,
        qualification: this.qualificationOutput,
        membership: this.membershipOutput,
        current_position: this.currentPositionOutput,
        previous_employment: this.previousEmploymentOutput,
        previous_industries: this.previousIndustriesOutput,
        skill: skillItems,
        reviewed: this.reviewed, //string
        is_recommended: this.isRecommended,
        b64img: this.b64img, //string
        pk: "0",
        date: this.formatDateNow, //this is edited date  always will be overwritten
        archived: this.archived, //
        email_author: this.emailAuthor, //only change during created new submission
        iv_team: this.ivTeam,
        email_editor: this.$store.state.auth.username, //always will be overwritten
        created_date: this.createdDate, ////only change during created new submission
        has_attachment: this.hasAttachment,
      };

      //branching, if no then remove
      if (!this.branchCurrentNDOutput) {
        delete body["nd_company"];
        delete body["asset_class"];
      }
      if (!this.branchNationalityOutput) {
        delete body["ethnicity"];
      }

      await this.$store.dispatch("auth/getAuth");
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${this.$store.state.auth.token}`
      ); // Must pass token for every call
      myHeaders.append("username", this.$store.state.auth.username); // send data to backend
      // console.log(JSON.stringify(body))
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      };

      if (this.formMode == "Edit") {
        const res = await fetch(`${APIURL}/update-person`, requestOptions);
        this.handleAttachment();
        alert("Thank you for the submission.");
      } else {
        const res = await fetch(`${APIURL}/create-person`, requestOptions);
        this.handleAttachment();
        //thank you message and redirect to home page
        alert(
          "Thank you for the submission.It will be reviewed by IMSS before appearing at the home page."
        );
      }
      // need to refactor this better
      this.showProcessButton = false;
      this.showLoading = true;
      this.isBtnDisable = true;
      //refresh data after submission
      this.redirectHome();
    }
  },
  encodeImageFileAsURL(element) {
    var file = this.$refs.file.files[0];
    // console.log("under encode Image File A URL", file)
    const fileSize = file.size / 1024 / 1024; //in MiB
    // console.log("file size", fileSize)
    if (fileSize > 0.5) {
      alert("File Size exceeds 500kb. Please reupload smaller file");
      this.$refs.file.value = null;
    } else {
      var reader = new FileReader();
      reader.onload = (event) => {
        this.b64img = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  encodeFileAsBlob(element) {
    var file = this.$refs.pdffile.files[0];
    const fileSize = file.size / 1024 / 1024; // in MiB
    console.log("file name", file.name);

    if (fileSize > 5) {
      alert("File size exceeds 5Mb. Please upload a smaller file.");
      this.$refs.pdffile.value = null;
    } else if (file.type !== "application/pdf") {
      alert("Only PDF files are allowed. Please upload a PDF file.");
      this.$refs.pdffile.value = null;
    } else {
      var reader = new FileReader();
      reader.onload = (event) => {
        var arrayBuffer = event.target.result;
        var blob = new Blob([arrayBuffer], { type: "application/pdf" });
        this.pdfFile = blob;
        this.pdfFileName = file.name;
      };
      reader.readAsArrayBuffer(file); //if edit form need to read if exist
      this.hasAttachment = true;
    }
  },

  async handleAttachment(element) {
    //need to pass id as well
    console.log("Blob:", this.pdfFileName.length);
    //   await this.$store.dispatch("auth/getAuth");
    if (this.pdfFileName.length > 0) {
      6;
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${this.$store.state.auth.token}`
      ); // Must pass token for every call
      myHeaders.append("username", this.$store.state.auth.username); // send data to backend
      var formData = new FormData();
      //check if empty then no need upload
      formData.append("file", this.pdfFile); //is this empty if not uploaded??

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
      };

      const res = await fetch(
        `${APIURL}/upload-blob?id=${this.id}`,
        requestOptions
      );

      if (!res.ok) {
        console.log("Error Uploading PDF");
      }
    }
  },
  async getAttachment() {
    await this.$store.dispatch("auth/getAuth");
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${this.$store.state.auth.token}`); // Must pass token for every call
    myHeaders.append("username", this.$store.state.auth.username); // send data to backend

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    const res = await fetch(
      `${APIURL}/get-blob-by-name/${this.id}.pdf`,
      requestOptions
    );

    if (res.ok) {
      const blob = await res.blob();
      var downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = this.candidateName + "_attachment.pdf";
      downloadLink.innerText = this.candidateName + "_attachment.pdf";

      this.hasAttachment = true;
    } else {
      console.log("Error");
      var downloadLink = document.createElement("a");
      downloadLink.innerText = "No attachment found";
      downloadLink.className = "italic";
      this.hasAttachment = false;
    }
    const downloadLinkContainer = document.getElementById(
      "downloadLinkContainer"
    );
    downloadLinkContainer.innerHTML = ""; // Clear any existing content
    downloadLinkContainer.appendChild(downloadLink);
  },

  initializeEditForm() {
    const dataEdit = this.getDataEdit();
    this.setBasicData(dataEdit);
    this.setRadioData(dataEdit);
    this.setMultiValueData(dataEdit);
    this.setCheckboxData(dataEdit);
    this.setSpecialFields(dataEdit);
    this.getAttachment();
  },
  initializeCreateForm() {
    this.createdDate = this.formatDateNow;
    this.emailAuthor = this.$store.state.auth.username;
    this.$store.dispatch("track/trackNewSubmission");
  },
  getDataEdit() {
    return (
      this.$store.state.data.ndList.find(
        (el) => el.id === this.$route.query.uuid
      ) || {}
    );
  },
  setBasicData(dataEdit) {
    this.id = dataEdit.id || "";
    this.candidateName = dataEdit.name || "";
    this.birthYear = dataEdit.birth_year || "";
    this.comments = dataEdit.comments || "";
    this.url = dataEdit.url || "";
    this.b64img = dataEdit.b64img || "";
    this.emailAuthor = dataEdit.email_author || "";
    this.emailEditor = dataEdit.email_editor || "";
    this.createdDate = dataEdit.created_date || "";
    this.ivTeam = dataEdit.iv_team || "";
  },
  setRadioData(dataEdit) {
    this.honorificOutput = dataEdit.honorific || "";
    this.genderOutput = dataEdit.gender || "";
    this.nationalityOutput = dataEdit.nationality || "";
    this.ethnicityOutput = dataEdit.ethnicity || "";
    this.countryOutput = dataEdit.residence_country || "";
    this.kAlumniOutput = dataEdit.k_alumni || "";
    this.currentNDOutput = dataEdit.k_nd?.[0]?.value || "";
  },
  setMultiValueData(dataEdit) {
    this.ndCompanyOutput = cloneDeep(dataEdit.nd_company) || [
      { id: 0, value: "" },
    ];
    this.assetClassOutput = cloneDeep(dataEdit.asset_class) || [
      { id: 0, value: "" },
    ];
    this.educationOutput = cloneDeep(dataEdit.education) || [
      { id: 0, value: "" },
    ];
    this.qualificationOutput = cloneDeep(dataEdit.qualification) || [
      { id: 0, value: "" },
    ];
    this.membershipOutput = cloneDeep(dataEdit.membership) || [
      { id: 0, value: "" },
    ];
    this.currentPositionOutput = cloneDeep(dataEdit.current_position) || [
      { id: 0, value: "" },
    ];
    this.previousEmploymentOutput = cloneDeep(dataEdit.previous_employment) || [
      { id: 0, value: "" },
    ];
    this.previousIndustriesOutput = cloneDeep(dataEdit.previous_industries) || [
      { id: 0, value: "" },
    ];
  },
  setCheckboxData(dataEdit) {
    this.skillOutputEdit = dataEdit.skill;
    dataEdit.skill.forEach((el) => {
      if (this.skillSetChoice.includes(el.value)) {
        this.skillOutput.push(el.value);
      } else {
        this.skillOutputOther.push(el);
      }
    });
  },
  setSpecialFields(dataEdit) {
    this.reviewed = dataEdit.reviewed === "Reviewed";
    this.archived = dataEdit.archived === "Archived";
    this.isRecommended =
      dataEdit.is_recommended === "Executive/ Senior Management" ||
      dataEdit.is_recommended === "Director";
  },
};
