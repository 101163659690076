<template>
  <div
    class="bg-cover bg-androGray font-roboto flex flex-row"
    v-if="this.$store.state.auth.authenticated"
  >
    <SideMenu class="hidden md:block" />
    <SideMenu
      class="block md:hidden z-50"
      v-if="this.$store.state.data.showMenu"
    />
    <div class="w-full md:w-[75%] mx-5 md:mx-auto">
      <TheHeader />
      <div class="py-5">
        <router-view />
        <TheEmailBtn />
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheEmailBtn from "./components/TheEmailBtn.vue";
import SideMenu from "./components/SideMenu.vue";

export default {
  components: { TheHeader, TheEmailBtn, SideMenu },
  data() {
    return {};
  },
  methods: {
    async doGetPaginatedData(offset) {
      await this.$store.dispatch("auth/getAuth");
      await this.$store.dispatch("data/getPaginatedData", [
        this.$store.state.auth.token,
        offset,
        20,
        this.$store.state.auth.adminRole,
      ]);
      await this.$store.dispatch("data/getLatest4Data", [
        this.$store.state.auth.token,
      ]);

      //copy last4NDList to last4NDListGlance
      this.$store.commit(
        "data/setlast4NDListGlance",
        this.$store.state.data.last4NDList
      );

      //fix remove everyone that is not less than 90 days from today
      //to do here
      this.$store.commit("data/setlast4NDList",
        this.$store.state.data.last4NDList.filter(item => {
            const itemDate = new Date(item.created_date); //
            const today = new Date();
            const diffTime = Math.abs(today - itemDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= 90;
  })

      )

      await this.$store.dispatch("news/getLatestNews", [
        this.$store.state.auth.token,
      ]);

      await this.$store.dispatch("lnd/getData", this.$store.state.auth.token);

      if (this.$store.state.filter.filterArray.length == 0) {
        await this.$store.dispatch(
          "filter/getFilterArray",
          this.$store.state.auth.token
        );
      }
    },
  },
  async created() {
    console.log(localStorage["retryCounter"]);

    if (localStorage["retryCounter"] === undefined) {
      localStorage["retryCounter"] = 0;
    }
    try {
      this.doGetPaginatedData(0);

      localStorage["retryCounter"] = 0; //reset retryCounter
    } catch {
      if (localStorage["retryCounter"] == 0) {
        console.log("after throw Error", localStorage["retryCounter"]);
        this.$store.dispatch("data/clearLocalStorage");
        this.doGetPaginatedData(0);
      }
      // console.log("continue here after retry")
      localStorage["retryCounter"] = 1;
    }
  },
};
</script>
