<template>
  <div class="w-64 xl:w-full ">
    <carousel ref="carouselLnD" :autoplay="3600">
      <slide v-for="entry in lndList" :key="entry.id" class="h-40 flex flex-col text-sm justify-start items-start text-left">
          <p class="font-semibold ">{{ entry.title }}</p>
          <span
            >{{ entry.day }}/{{ entry.month }}/{{ entry.year }} -
            {{ entry.topic }} by {{ entry.presenter }} -
            <strong>{{ isPast(entry) ? "Past" : "Upcoming" }}</strong></span
          >

      </slide>

      <template #addons>
        <!-- <navigation /> -->
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapState } from 'vuex';
import { ref } from 'vue'

export default {
  name: "LnDCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup(){
    const carouselLnD = ref(null)
    return {carouselLnD}
  },
  computed:{
    ...mapState('lnd', ['lndList']),

  },
  methods: {
    isPast(entry) {
      const today = new Date();
      const entryDate = new Date(entry.year, entry.month - 1, entry.day);
      return entryDate < today;
    },
    handleResize() {
    this.carouselLnD.restartCarousel()
    },
  },

  mounted(){
    this.carouselLnD.restartCarousel()
    window.addEventListener('resize', this.handleResize);

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  
};
</script>

<style scoped>

</style>
