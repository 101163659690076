<template>
  <div>
    <div v-if="!isShowTable">
      <div
        id="role-change"
        class="my-1"
        v-if="(this.$store.state.auth.view == 'View.Admin')&&(this.$route.name == 'home')"
      >
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="form-checkbox"
            v-model="adminRole"
            @change="applyAdminRole"
          />
          <span class="ml-2">Activate Admin Role</span>
        </label>
      </div>
      <div class="">
        <div id="latest" class="pr-3 grid grid-cols-2 gap-2 md:grid-cols-4 w-full md:gap-4">
          <div v-if="true" v-for="meta in latestList" :key="meta.id">
            <BaseDataCard :meta="meta" :isAdmin="isAdmin" :isNew="true" />
          </div>
        </div>
        <div id="all-data" class="pr-3 grid grid-cols-2 gap-2 md:grid-cols-4 w-full md:gap-4">
          <div v-if="true" v-for="meta in filterList" :key="meta.id">
            <BaseDataCard :meta="meta" :isAdmin="isAdmin" :isNew="false" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BaseDataCard from "./BaseDataCard.vue";
import BaseDataTable from "./BaseDataTable.vue";
import ActiveDataCard from "./ActiveDataCard.vue";
import { mapGetters } from 'vuex';
export default {
  name: "TheData",
  props: {
    isAdmin: Boolean,
    isShowTable: Boolean,
    view: String,
  },
  data() {
    return {
      adminRole: this.$store.state.auth.adminRole,
    };
  },
  computed: {
    filterList() {
      if (this.view == "search") {
        var x = this.$store.state.search.searchResult; 
      } else {
        var x = this.$store.state.data.ndList.filter(nd => 
          !this.latestList.some(latest => latest.id === nd.id)
        );
      }
      return x;
    },

    latestList() {
      if (this.view == "search") {
        var x = []
      } else {
        var x = this.$store.state.data.last4NDList;
      }
      return x;
    },
    ...mapGetters('filter', ['isAnyFilterChecked']),

  },
  methods: {
    async applyAdminRole(value) {
      //neeed to change this for search page
      this.$store.commit("auth/setAdminRole", this.adminRole);
      await this.$store.dispatch("auth/getAuth");
      // reset data then call data
      this.$store.commit("data/resetNDList");
      if (this.adminRole) {
        this.$store.dispatch("data/getAllData", [this.$store.state.auth.token]);
      } else {
        await this.$store.dispatch("data/getPaginatedData", [
          this.$store.state.auth.token,
          0,
          20,
          this.$store.state.auth.adminRole,
        ]);
      }
    },
  },
  components: { BaseDataCard, BaseDataTable, ActiveDataCard },
};
</script>
