export default {
    formatDateNow() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
  
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
  
        return [year, month, day].join("-");
      },
      getNDCompanyList() {
        return this.ndCompanyList;
      },
      branchNationalityOutput() {
        return this.nationalityOutput == "Malaysian";
      },
      branchCurrentNDOutput() {
        return this.currentNDOutput == "Yes";
      },
      getPP() {
        return this.b64img;
      },

}