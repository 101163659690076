<template>

<div class="flex flex-col text-black text-sm gap-y-4"> 
<p class="">
  The Institute of Corporate Directors Malaysia (ICDM) ® promotes excellence in governance as we believe that Governance is a key driver of sustainability. By equipping directors with the right skills, knowledge and mindset, ICDM ® aims to enhance the professionalism and effectiveness of boards.
  </p>
  <p>
    ICDM ® offers a comprehensive suite of board advisory and consultancy services, and runs a series of development and advocacy programmes to build a robust corporate governance culture in Malaysia. Established by the Securities Commission (SC) Malaysia and supported by Bank Negara Malaysia, Bursa Malaysia and the Capital Market Development Fund, ICDM ® serves as the national institute of directors and aims to be the leading influence of excellence in governance. Visit <a href="https://icdm.com.my/" target="_blank" class="hover:cursor-pointer hover:text-secondBlue underline">https://icdm.com.my/</a> for more details.
  </p>

<p>
      For access to ICDM's resources and services, please email <span class="underline italic">farrah.abidin@khazanah.com.my</span>
</p>

<p>
      To learn more about ICDM director programme, visit their website at <a href="https://pulse.icdm.com.my/director-programmes" target="_blank" class="underline  hover:text-secondBlue">https://pulse.icdm.com.my/director-programmes/</a>
</p>
      
</div>

</template>

<script></script>
