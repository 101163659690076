<template>
  <div class="bg-cover font-roboto">
    <div class="mx-auto py-5 ">
      <form v-if="view=='View.Admin'" @submit.prevent="addEntry" class="mb-5 p-4 bg-white shadow-md rounded">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input v-model="newEntry.day" placeholder="Day (e.g. 1)" type="number" required class="p-2 border border-black rounded" />
          <input v-model="newEntry.month" placeholder="Month (e.g. 8)" type="number" required class="p-2 border border-black  rounded" />
          <input v-model="newEntry.year" placeholder="Year (e.g. 2024)" type="number" required class="p-2 border border-black rounded" />
          <input v-model="newEntry.topic" placeholder="Topic (e.g. Sustainability Driving Growth) " required class="p-2 border border-black rounded" />
          <input v-model="newEntry.presenter" placeholder="Presenter" required class="p-2 border border-black rounded" />
          <input v-model="newEntry.title" placeholder="Title (e.g. Andromeda L&D Training)" required class="p-2 border border-black rounded" />
        </div>
        <div class="mt-4 flex space-x-2">
          <button type="submit" class="p-2 bg-firstBlue text-white rounded">{{ isEditMode ? 'Save' : 'Add Entry' }}</button>
          <button type="button" @click="resetForm" class="p-2 bg-gray-500 text-white rounded">Reset</button>
        </div>
      </form>

      <ul class="space-y-4">
        <li v-for="entry in lndList" :key="entry.id" class="p-4 bg-white shadow-md rounded flex justify-between items-center w-full">
            <div class="flex flex-col items-start">
              <h3 class="text-xl font-semibold">{{ entry.title }}</h3>
             <span>{{ entry.day }}/{{ entry.month }}/{{ entry.year }} - {{ entry.topic }} by {{ entry.presenter }} - <strong>{{ isPast(entry) ? 'Past' : 'Upcoming' }}</strong></span>
            </div>
            <div v-if="view=='View.Admin'" class="flex md:flex-row flex-col items-center gap-2">
              <button @click="editEntry(entry)" class="p-2 bg-firstPurple text-white rounded">Edit</button>
              <button @click="deleteEntry(entry.id)" class="p-2 bg-red-500 text-white rounded">Delete</button>
            </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      newEntry: {
        day: '',
        month: '',
        year: '',
        topic: '',
        presenter: '',
        title: ''
      },
      isEditMode: false,
      editIndex: null
    };
  },
  computed: {
    ...mapState('lnd', ['lndList']),
    ...mapState('auth',['view'])
  },
  methods: {
    async addEntry() {
      if (!this.validateYear(this.newEntry.year)) {
        alert('Please enter a valid year in YYYY format.');
        return;
      }
      const token = this.$store.state.auth.token;
      if (this.isEditMode) {
        await this.$store.dispatch('lnd/updateData', [{ ...this.newEntry }, token]);
      } else {
        await this.$store.dispatch('lnd/addData', [{ ...this.newEntry }, token]);
      }
      this.resetForm();
    },
    editEntry(entry) {
      this.newEntry = { ...entry };
      this.isEditMode = true;
    },
    async deleteEntry(id) {
      const token = this.$store.state.auth.token;
      await this.$store.dispatch('lnd/deleteData', [id, token]);
    },
    resetForm() {
      this.newEntry = {
        day: '',
        month: '',
        year: '',
        topic: '',
        presenter: '',
        title: ''
      };
      this.isEditMode = false;
      this.editIndex = null;
    },
    isPast(entry) {
      const today = new Date();
      const entryDate = new Date(entry.year, entry.month - 1, entry.day);
      return entryDate < today;
    },
    validateYear(year) {
      const yearPattern = /^\d{4}$/;
      return yearPattern.test(year);
    }
  },
  async created() {
    await this.$store.dispatch('auth/getAuth');
    await this.$store.dispatch('lnd/getData', this.$store.state.auth.token);
  }
};
</script>