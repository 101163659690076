<template>
	<div class="">
		<button v-if="!isAnySelected" class="px-2 text-slate-500 font-semibold text-sm p-1" disabled>
			Select Item(s) To Export
		</button>
		<div class="flow-root">
			<div class="float-left">
				<p class="text-slate-500 font-semibold text-sm">
					{{ countSelectedDisplay }}
				</p>
			</div>

			<div class="float-right">
				<button v-if="isAnySelected"
					class="inline-flex px-2 mt-1 py-1 rounded-md bg-firstBlue text-white font-semibold text-sm p-1 hover:bg-thirdBlue"
					@click="downloadCSV">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
						stroke="currentColor" class="w-4 h-4">
						<path stroke-linecap="round" stroke-linejoin="round"
							d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
					</svg>

					<span> Export to CSV</span>
				</button>
			</div>
		</div>

		<div class="mt-2 overflow-x-auto relative shadow-md sm:rounded-lg h-[720px]">
			<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
				<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
					<tr>
						<th scope="col" class="py-3 px-3">
							<input type="checkbox" class="form-checkbox" v-model="isSelectAll" @change="anySelected" />
						</th>
						<th v-for="col in colNames" scope="col" class="py-3 px-6 resize-x">
							<div class="flex items-center">
								{{ col }}
								<!-- <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="ml-1 w-3 h-3"
										aria-hidden="true" fill="currentColor" viewBox="0 0 320 512">
										<path
											d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z">
										</path>
									</svg></a> -->
							</div>
						</th>
						<th scope="col" class="py-3 px-3" v-show="isAdmin">
							REVIEWED
						</th>
						<th scope="col" class="py-3 px-3" v-show="isAdmin">
							ARCHIVED
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="row in dataTable"
						v-show="isAdmin || row['reviewed']">
						<td class="py-3 px-3">
							<input type="checkbox" class="form-checkbox" v-model="checkboxExportObject[row.id]"
								@change="anySelected" />
						</td>
						<th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
							{{ row.name }}
						</th>
						<td class="py-4 px-6">
							{{ row.honorific }}
						</td>
						<td class="py-4 px-6">
							{{ row.birth_year }}
						</td>
						<td class="py-4 px-6">
							{{ row.nationality }}
						</td>
						<td class="py-4 px-6">
							{{ row.ethnicity }}
						</td>
						<td class="py-4 px-6">
							{{ row.residence_country }}
						</td>
						<td class="py-4 px-6">
							{{ row.k_nd }}
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.nd_company }}</span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.asset_class }} </span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.education }}</span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.qualification }}</span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3"> {{ row.membership }}</span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.current_position }} </span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3"> {{ row.previous_employment }} </span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3"> {{ row.previous_industries }} </span>
						</td>
						<td class="py-4 px-6 whitespace-pre-line">
							<span class="line-clamp-3">{{ row.skill }} </span>
						</td>
						<td class="py-4 px-6">
							<span class="line-clamp-3"> {{ row.comments }} </span>
						</td>
						<td class="py-4 px-6">
							{{ row.url }}
						</td>
						<td class="py-4 px-6" v-show="isAdmin">
							{{ row.reviewed }}
						</td>

						<td class="py-4 px-6" v-show="isAdmin">
							{{ row.archived }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseDataTable",
	props: {
		meta: Array,
		//isAdmin: Boolean
	},
	data() {
		return {
			// dataTable: [],
			colNames: [
				"Candidate Name",
				"Honorific",
				"Birth Year",
				"Nationality",
				"Ethnicity",
				"Country of Residence",
				"Current Khazanah ND?",
				"Name of Investee Co or SPV",
				"Asset Class",
				"Education Level",
				"Professional Qualification(s)",
				"Professional Membership(s)",
				"Current Position(s)",
				"Previous Employment",
				"Previous Industries",
				"Skill Set",
				"Team Comments",
				"LinkedIn URL",
			],
			rawColNames: [
				"id",
				"name",
				"honorific",
				"birth_year",
				"gender",
				"nationality",
				"ethnicity",
				"residence_country",
				"comments",
				"url",
				"k_nd",
				"nd_company",
				"asset_class",
				"education",
				"qualification",
				"membership",
				"current_position",
				"previous_employment",
				"previous_industries",
				"skill",
				"reviewed",
				"archived"
			],
			checkboxExportObject: {},
			isSelectAll: false,
			isAnySelected: false,
			countSelectedDisplay: "",
		};
	},
	computed: {
		isAdmin() {
			return this.$store.state.auth.adminRole;
		},
		dataTable() {
			let data = this.meta.map((el) => {
				let tempObj = {};
				this.rawColNames.forEach((rawCol) => {
					if (rawCol in el) {
						if (Array.isArray(el[rawCol])) {
							let tempString = "";
							// turn into string
							el[rawCol].forEach((val2, index) => {
								// console.log(val2.value)
								//!!!!!!!!!!!!!!! fix no newline if lastline
								if (index != (el[rawCol].length - 1)) {//check if it is NOT the last item

									tempString += val2.value + "\n";
								} else {

									tempString += val2.value;
								}
							});
							tempObj[rawCol] = tempString;
						} else {
							tempObj[rawCol] = el[rawCol];
						}
					} else {
						tempObj[rawCol] = "";
					}
				});
				return tempObj;
			});
			this.checkboxExportObject = {}; //reset
			this.meta.forEach((el) => {
				if (el.reviewed) {
					this.checkboxExportObject[el.id.toString()] = false;
				}
			});
			return data;
		},
	},
	methods: {
		editForm() {
			this.$router.push({
				name: "EditView",
				query: {
					uuid: this.meta.id,
				},
			});
		},

		anySelected() {
			//when user click any checkbox
			//check which ID is selected
			//checkboxExportObject is manipulated in computed dataTable()
			var checkedID = Object.keys(this.checkboxExportObject).filter((el) => {
				return this.checkboxExportObject[el] == true;
			});

			if (checkedID.length) {
				//if more than 0
				//button active
				this.isAnySelected = true;
				// console.log("in AnySelectedMethod", checkedID.length.toString());
				let len = checkedID.length.toString();
				this.countSelectedDisplay = len + " Item(s) Selected";
			} else {
				this.isAnySelected = false;
				this.countSelectedDisplay = "";
			}
		},

		downloadCSV() {
			//get id that is true only
			var checkedID = Object.keys(this.checkboxExportObject).filter((el) => {
				return this.checkboxExportObject[el] == true;
			});
			//filter from the checkboxes then pass below

			var checkedDataTable = this.dataTable.filter((el) => {
				return checkedID.includes(el.id);
			});
			const csvString = [
				this.colNames,
				...checkedDataTable.map((item) =>
					[
						item.name,
						item.honorific,
						item.birth_year,
						item.nationality,
						item.ethnicity,
						item.residence_country,
						item.k_nd,
						item.nd_company,
						item.asset_class,
						item.education,
						item.qualification,
						item.membership,
						item.current_position,
						item.previous_employment,
						item.previous_industries,
						item.skill,
						item.comments,
						item.url,
					].map((s) => {
						const regex = /"/g;
						if (!s) {
							s = "";
						}
						return '"' + s.toString().replace(regex, '"') + '"'; //wrap the item with "double quotes" replace inner double quotes from " to ""
					})
				),
			]
				.map((el) => el.join(","))
				.join("\n");


			const anchor = document.createElement("a");
			// read byte order mark to understand this %EF%BB%BF
			anchor.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvString);
			anchor.target = "_blank";
			anchor.download = "nddb_exportdata.csv";
			anchor.click();

			// store data
			this.$store.dispatch("track/trackExportButton")



		},
	},
	mounted() {

	},
	watch: {
		isSelectAll() {
			this.dataTable.forEach((el) => {

				//if admin then selectAll including not yet reviewed, 

				if (this.isAdmin) {
					this.checkboxExportObject[el.id.toString()] = this.isSelectAll;
				} else {
					// if not admin only select reviewed only
					if (el.reviewed) {
						this.checkboxExportObject[el.id.toString()] = this.isSelectAll;
					}
				}


			});
		},
		
	},
};
</script>
