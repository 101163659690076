// processData.js
export async function fetchData({ commit, dispatch,rootState }, [url, token]) {
  commit("setHomeLoading", true);
  let isPaginatedURL = url.includes("get-items-paginated")
  let isLatest = url.includes("get-latest-items")
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var body = rootState.filter.filterArray 
    var requestOptions = { headers: myHeaders,
                          body: JSON.stringify({"filters":body}) || "{}",
                          method: "POST"};
   
    const res = await fetch(url, requestOptions);
    if (res.status == 401) {
      throw new Error(res.status);
    }

    const rawData = await res.json();
    if(isPaginatedURL){
      var data = rawData["items"];
      var nextOffset = rawData["offset"];
    }else if(isLatest){
      var data = rawData["items"];
    }else{//admin?
      var data = rawData
    }
    // Add this year age
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    console.log("processData", data);
    processData(data, currentYear);
   
    if (isPaginatedURL) {
      commit("setNDList", data);
      commit("setOffset", nextOffset);
      commit("setActiveID", "");
      dispatch("track/trackHome", null, { root: true });
      dispatch("getCheckboxExport");
      
     
    }else if (isLatest) {
      commit ("setlast4NDList", data);
    } else{
      commit("setDetailsData", data[0]);
    }

    commit("setHomeLoading", false);
  
  } catch (error) {
    console.warn(error);
    // Should do something here
  }
}

export function processData(data, currentYear) {
    data.forEach((el, index) => {
      let age = currentYear - el.birth_year;
      if (el.birth_year == null) {
        age = "N/A";
      }
      data[index]["age"] = age;
  
      switch (true) {
        case age < 40:
          data[index]["ageRange"] = "below 40";
          break;
        case age < 70:
          data[index]["ageRange"] = "40 to 69";
          break;
        case age < 130:
          data[index]["ageRange"] = "70 and above";
          break;
        default:
          data[index]["ageRange"] = "N/A";
      }
  
      switch (true) {
        case data[index]["archived"] == true:
          data[index]["archived"] = "Archived";
          break;
        case data[index]["archived"] == false:
          data[index]["archived"] = "Live";
          break;
      }
  
      switch (true) {
        case data[index]["is_recommended"] == true:
          data[index]["is_recommended"] = "Executive/ Senior Management";
          break;
        case data[index]["is_recommended"] == false:
          data[index]["is_recommended"] = "Director";
          break;
        case data[index]["is_recommended"] == undefined:
          data[index]["is_recommended"] = "Director";
          break;
      }
  
      switch (true) {
        case data[index]["reviewed"] == true:
          data[index]["reviewed"] = "Reviewed";
          break;
        case data[index]["reviewed"] == false:
          data[index]["reviewed"] = "Pending";
          break;
      }
  
      let presetEthnicity = ["Malay", "Chinese", "Indian"];
      if (!presetEthnicity.includes(data[index]["ethnicity"])) {
        data[index]["ethnicity"] = "Other";
      }
  
      if (data[index]["iv_team"] === undefined) {
        data[index]["iv_team"] = "";
      }
    });
  }

