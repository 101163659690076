<template>
    <teleport to="#teleport-modal">
        <div id="modal-bg" class="fixed z-10 inset-0 overflow-hidden bg-black bg-opacity-25 flex justify-center backdrop-blur-sm mx-0 md:mx-auto py-10"
        v-if="this.$store.state.filter.showModal"
        >
            <div ref="theModal" class="bg-white rounded-xl w-3/4 my-auto">
                <button class="text-right w-full pt-1 pr-3" @click="this.$store.commit('filter/setShowModal',false)">
                    X

                </button>
                <div class="pl-5 font-bold">Filters</div>
                <div class="mx-5 my-3">
                    <BaseFilterCategory/>
                </div>
                

            </div>


        </div>


    </teleport>
</template>

<script>

import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useStore } from 'vuex'
import BaseFilterCategory from './BaseFilterCategory.vue';
import BorderLine from './BorderLine.vue';

export default {
        setup(){
            const theModal = ref(null)
            const store = useStore()
            onClickOutside(theModal, () => {
                console.log('clicked outside')
                store.commit('filter/setShowModal', false)
            })
            return { theModal }
        },

    name: "FilterModal",
    components: { BaseFilterCategory,BorderLine },
    data(){
        return{
            show: false
        }
    }


    }
</script>
