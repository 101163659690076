import { createStore } from "vuex";
import "tw-elements";
import auth from "./modules/auth";
import data from "./modules/data";
import track from "./modules/track";
import search from "./modules/search";
import filter from "./modules/filter";
import lnd from "./modules/lnd";
import news from "./modules/news";

//https://medium.com/swlh/building-large-scale-applications-with-vuex-6d7e8ce0dfef

export default createStore({
  modules: {auth,data,track,search,filter,lnd,news},
});
