<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto p-5 bg-white shadow-md rounded-xl">
      <div v-for="news in newsList" :key="news.id" >
        <div class="flex flex-col items-start gap-y-3">
          <p>{{ news.source }}</p>
            <a :href="news.link" target="_blank" rel="noopener noreferrer" class="hover:cursor-pointer font-semibold">{{ news.title }}</a>
          <p>Published Date: {{ news.pubdate }}</p>
          <div id="kw" class="flex flex-wrap gap-x-1">
            <p>Keywords: </p>
            <p v-for="(keyword, index) in news.keywords" :key="keyword" class="inline-block">
              {{ keyword.value }}<span v-if="index < news.keywords.length - 1">, </span>
            </p>
          </div>
        </div>
        

        <BorderLine text=""/>
      </div>
      <div v-for="index in 10" :key="index">
        <BaseSkeletonLoading v-show="isLoading" />
      </div>

      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSkeletonLoading from "../components/BaseSkeletonLoading.vue";
import BorderLine from '@/components/BorderLine.vue';


export default {
  
  components: {BaseSkeletonLoading,BorderLine },
  data(){
    return{
      debounceTimeout: null,
    }
  },
  methods:{
    async doGetPaginatedData(offset) {
      await this.$store.dispatch("auth/getAuth");
      await this.$store.dispatch("news/getPaginatedData", [
        this.$store.state.auth.token,
        offset,
        30,
      ]);
    },
    handleScroll() {
      if (
        !( this.isLoading || this.nextOffset==-1) // one more condition is that ad lagi ke records nk load
      ) {
        const bottomOfWindow =
          window.innerHeight + window.scrollY >=
          document.documentElement.offsetHeight - 10;

        if (bottomOfWindow && !this.isLoading && this.isPageNews ) {
          this.doGetPaginatedData(this.nextOffset);
        }
      }
    },
    debounce(func, wait) {
      return (...args) => {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
  },
  computed:{
    ...mapState('news', ['newsList','isLoading','nextOffset']),
    isPageNews(){
      return this.$route.name==="NewsView";
    }
  },
  
  async created(){
    window.addEventListener("scroll", this.debounce(this.handleScroll, 200));
    this.doGetPaginatedData(0);
     
   },
   beforeUnmount() {
    window.removeEventListener("scroll", this.debounce(this.handleScroll, 200));
  },

}
</script>
