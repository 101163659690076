<template>
  <div class="pt-6">
    <div class="grid grid-cols-1 w-full gap-y-6">
      <div
        v-for="(regulatory, index) in regulatoryList"
        :key="index"
        class="bg-white shadow-md rounded-lg p-4"
      >
        <a
          :href="regulatory.link"
          target="_blank"
          class="hover:cursor-pointer hover:text-secondBlue flex flex-row items-center"
        >
          <img
            v-if="regulatory.type === 'pdf'"
            src="@/assets/pdflogo.png"
            class="w-6 h-6 mr-3"
            alt=""
          />
          <img
            v-if="regulatory.type === 'docx'"
            src="@/assets/docx_icon.png"
            class="w-6 h-6 mr-3"
            alt=""
          />
          <p class="font-semibold">{{ regulatory.title }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
      regulatoryList: [
        {
          title: "Malaysian Code on Corporate Governance",
          link :"https://www.sc.com.my/api/documentms/download.ashx?id=239e5ea1-a258-4db8-a9e2-41c215bdb776",
          type: "pdf"
        },
        {
         title: "Bursa Listing Requirements",
         link:"https://www.bursamalaysia.com/regulation/listing_requirements/main_market/listing_requirements",
         type:"pdf"
        },
        {
         title: "Companies Act 2016",
         link:"https://www.ssm.com.my/Pages/Legal_Framework/Document/Companies%20Act%202016_Akta%20777_BI%20(1.8.2022).pdf",
         type:"pdf"
        }
  

      ],
    };
  },
};
</script>
