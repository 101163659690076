<template>
    <div id="alldata-outline" class="w-full text-md my-3 border-b-2 border-slate-600">{{ text }}</div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>


