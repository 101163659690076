<template>
    <div class="-mt-6 text-sm" v-if="name.length>0">
        <button @click="checkDuplicateName" class="rounded-lg text-white bg-firstBlue px-3 py-1 mx-1">Check Potential Name Duplicate</button>

        <div class="w-full bg-white shadow-xl py-3 px-3 rounded-lg mt-3" v-show="showDuplicateBox">

            <div class="flex flex-row items-start">
                <p class="w-full font-bold">
                    Below are potential duplicate matching from the database. The results below highlight the name that is similar to the name you have entered. Please review the list below and ensure that the name you have entered is unique:
                </p>
                <button @click="handleClose" class="flex flex-row justify-end ml-3 -mt-2 hover:text-firstBlue">
           
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="" class="w-6 h-6">
  <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
</svg>
                </button>
            
            </div>


        
        <p v-if="!nameCheckLoading" class="text-black italic hover:text-blue-500 mx-1 my-0.5" v-for="(data,i) in nameCheckResult">
           <router-link :to="'/details?uuid='+ data.id" >
           {{i+1}}.
           <span>
             {{ data.name }}
           </span>
        </router-link> 
        </p>
        
        <p v-if="nameCheckLoading" class="text-black  mt-2 italic mx-1">Checking...</p>
        <p v-if="(!nameCheckLoading) && nameCheckResult.length==0" class="text-black  mt-2 italic mx-1">No potential duplicate</p>

    </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
export default {
    name: 'CheckDuplicate',
    props: {
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showDuplicateBox: false
        }
    },
    computed:{
        ...mapState('search',["nameCheckResult","nameCheckLoading"])
    },
    methods: {
        checkDuplicateName() {
            this.$store.dispatch('search/getNameCheck', this.name);
            this.showDuplicateBox = true

        },
        handleClose(){
            this.$store.commit('search/setNameCheckResult',[])
            this.showDuplicateBox = false
        }
    }
};
</script>