import { root } from "postcss";
import { APIURL } from "@/utils/authConfig";
import { fetchData } from '@/utils/processData.js';

export default {
  namespaced: true,
  state: {
    ndList: [],
    homeLoading: true,
    checkboxExport: [],
    activeID: "",
    nextOffset: 0,
    detailsData: {},
    last4NDList: [],
    last4NDListGlance: [],
    showMenu:false
  },
  mutations: {
    setHomeLoading(state, value) {
      state.homeLoading = value;
    },
    setNDList(state, value) {
      if(state.ndList.length ===0){state.ndList = value;}else{
        state.ndList = state.ndList.concat(value);
      }
      
    },

    setlast4NDListGlance(state, value) {
      state.last4NDListGlance = value;
    },


    setlast4NDList(state, value) {
      state.last4NDList = value;
    },
    setDetailsData(state, value) {
      state.detailsData = value;
    },

    initCheckboxExport(state, value) {
      state.checkboxExport = value;
    },
    setNDListCheckboxExport(state, id) {
      state.ndList[id].checked = !state.ndList[id].checked;
    },
    setActiveID(state, value) {
      state.activeID = value;
    },
    setOffset(state, value) {
      state.nextOffset = value;
    },
    resetNDList(state){
      state.ndList = [];
    },
    setNDListAll(state,value){
      state.ndList = value
      //create a call for all. 
    },
    setShowMenu(state,value){
      state.showMenu = value;
    }
  },
  actions: {
    clearLocalStorage({ commit }) {
      console.log("clear Local Storage except retry Counter");
      var keyArr = Object.entries(localStorage).filter(([key, value]) => {
        return key != "retryCounter";
      });
      // console.log("key Arr", keyArr);
      keyArr.forEach((key) => {
        delete localStorage[key[0]];
      });
    },
    
    getCheckboxExport({ commit, state }) {
      //id and checked
      var tempArray = [];
      state.ndList.forEach((el) => {
        tempArray.push({ id: el.id, checked: false });
      });
      // console.log(tempArray)
      commit("initCheckboxExport", tempArray);
    },

    async getPaginatedData({ commit, dispatch,rootState }, [token, offset, itemSize]) {
      let sortQuery = "";
      if (rootState.filter.sortValue){
        sortQuery = `&sort_by=${rootState.filter.sortValue}`;
      }
      const url = `${APIURL}/get-items-paginated?offset=${offset}&item_size=${itemSize}${sortQuery}`;
      await fetchData({ commit, dispatch,rootState }, [url, token]);
    },
    async getAllData({ commit, dispatch,rootState}, token) {
      let sortQuery = "";
      if (rootState.filter.sortValue){
        sortQuery = `&sort_by=${rootState.filter.sortValue}`;
      }
      const url = `${APIURL}/get-items-paginated?admin_view=true${sortQuery}`;
      await fetchData({ commit, dispatch,rootState }, [url, token]);
    },

    async getLatest4Data({ commit, dispatch,rootState }, token) {
      const url = `${APIURL}/get-latest-items`;
      await fetchData({ commit, dispatch,rootState }, [url, token]);

    },

    async getDataByID({ commit, dispatch,rootState }, [id, token]) {
      const url = `${APIURL}/get-item/${id}`;
      await fetchData({ commit, dispatch,rootState }, [url, token]);
    },

    async downloadAttachment({ state, dispatch ,rootState}, [id, fileName]) {
      let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW! need to do tracking
      document.body.style.cursor = "wait";
      await dispatch("getAuth");
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      const res = await fetch(
        `${APIURL}/get-blob-by-name/${id}.pdf`,
        requestOptions
      );
      if (res.ok) {
        const blob = await res.blob();
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName + ".pdf");
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
      }
      document.body.style.cursor = "default";
    },
    toggleMenu({commit,state}){
      console.log("toggle menu")
      commit("setShowMenu",!state.showMenu)
    }
  },
};
