<template>
  <div class="flex flex-col gap-y-6">
    <div id="iic" class="flex flex-col gap-y-3 bg-white rounded-3xl p-6 shadow-md">
      <p class="font-bold">Institutional Investor Council (“IIC”)</p>

      <div class="flex flex-col md:flex-row gap-y-2 md:gap-x-2 items-center">
        <img src="@/assets/iic.png" class="h-24 -auto" alt="" />
        <p class="text-sm">
        Khazanah is a member of the IIC, a platform that aims to represent and
        encourage collaboration amongst Malaysian institutional investors on
        system-wide corporate governance issues, through among others the
        effective adoption of the Malaysian Code for Institutional Investors
        ("MCII"). Khazanah’s peer members of the IIC include other GLICs (i.e.,
        EPF, PNB, LTH and KWAP) and private fund managers (e.g., Amundi,
        Eastspring, Aberdeen, etc.) and representation on the IIC Council are
        typically the respective CEOs or CIOs. For more information on the IIC, please refer <a href=" https://www.iicm.org.my/" target="_blank" class="hover:cursor-pointer hover:text-secondBlue underline"> https://www.iicm.org.my/</a>
      </p>


      </div>
      
      


      <div class="flex flex-col md:flex-row md:justify-between items-center text-sm mt-3  mx-auto gap-12">
        <div class="flex flex-col items-center">
          <img src="@/assets/dhh.jpg" class="h-32 w-32" alt="" />
          <p class="font-semibold mt-1">Datuk Hisham Hamdan</p>
          <p>Council Board Member</p>
        </div>

        <div class="flex flex-col items-center">
          <img src="@/assets/ks.jpg" class="h-32 w-32" alt="" />
          <p class="font-semibold mt-1">Goh Keat Siang</p>
          <p>Working Committee member</p>
        </div>

        <div class="flex flex-col items-center">
          <img src="@/assets/aliaa.jpg" class="h-32 w-32" alt="" />
          <p class="font-semibold mt-1">Teh Nor Aliaa Mohamad Nadzim</p>
          <p>Working Committee member</p>
        </div>
      </div>
    </div>
    <div id="club30" class="flex flex-col gap-y-3 bg-white rounded-3xl p-6 shadow-md">
      <p class="font-bold">30% Club</p>
      <div class="flex flex-col gap-y-2 md:flex-row md:gap-x-2 items-center">
        <img src="@/assets/30percentclub.jpg" class="h-28 w-auto" alt="" />
      <p class="text-sm">
        A business campaign aiming to boost the number of women in board seats
        and executive leadership of companies all over the world. More than
        1,000 board chairs and CEOs across more than 20 countries have already
        signed up as members to deliver at least 30% female representation at
        both levels – considered as the critical mass at which minority voices
        become heard. Our ultimate goal is parity. Please refer <a href="https://30percentclub.org/" target="_blank" class="hover:cursor-pointer hover:text-secondBlue underline">https://30percentclub.org/</a> for more information on the 30% Club.
      </p>

      </div>
      <div class="flex flex-col md:flex-row md:justify-between items-center text-sm mt-3  mx-auto gap-12">
        <div class="flex flex-col items-center">
          <img src="@/assets/dfz.jpg" class="h-32 w-32" alt="" />
          <p class="font-semibold mt-1">Dato’ Amirul Feisal Wan Zahir</p>
          <p>Khazanah’s Advocate</p>
        </div>

        <div class="flex flex-col items-center">
          <img src="@/assets/yan.jpg" class="h-32 w-32" alt="" />
          <p class="font-semibold mt-1">Nurazeyan Khalis</p>
          <p>Khazanah representative as Council Member</p>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
import BorderLine from "@/components/BorderLine.vue";
export default {
  components: {
    BorderLine,
  },
};
</script>
