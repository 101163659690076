<template>
  <div class="my-5 px-6">
    <section v-show="!showLoading" class="text-gray-800">
      <div class="flex flex-wrap gap-6">
        <p class="font-semibold text-justify">
          The Andromeda Talent Database is a database of Khazanah’s potential
          independent directors, nominee directors (ND) and senior management
          candidates for our investee companies that is built in a web-app.
          Details of the talent pool include name, education, employment history
          and etc. We invite the M&M Teams and any staff approved for access to
          contribute credible candidates and collectively construct the
          database. Please fill out the form below:

          <br />
          <br />
          <span class="font-normal italic">* indicates required</span>
        </p>

        <div
          id="form-container"
          class="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full px-3"
        >
          <form
            class="flex flex-col gap-10"
            @submit.prevent
            v-on:keydown.enter.prevent
          >
            <div>
              <span class="text-gray-700 font-semibold">Submission by:</span>

              <div class="flex flex-row items-center">
                <span>Email: </span>
                <input
                  v-model="emailAuthor"
                  class="form-input rounded-lg w-full border-none bg-gray-100 italic"
                  disabled
                  placeholder=""
                />
              </div>
              <div class="flex flex-row items-center">
                <span>Team: </span>
                <TeamDropdown
                  class=""
                  :formMode="formMode"
                  :editDataAnswer="ivTeam"
                  :answer="ivTeam"
                  :choiceList="teamChoice"
                  placeholder=""
                  @answerChange="setData($event, 'ivTeam')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold">Honorific*</span>
              <div id="honorific-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="honorificOutput"
                  :choiceList="honorificChoice"
                  :hasOther="true"
                  category="honorific"
                  @answerChange="setData($event, 'honorificOutput')"
                />
              </div>
            </div>

            <label class="block">
              <span class="text-gray-700 font-semibold">Candidate Name*</span>
              <input
                v-model.trim="candidateName"
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
              />
            </label>

            <CheckDuplicate :name="candidateName"/>

            <label class="block">
              <span class="text-gray-700 font-semibold">Year of Birth</span>
              <p class="italic text-sm">
                Please leave it blank if no information is available
              </p>
              <input
                v-model.number="birthYear"
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
              />
            </label>

            <div class="block">
              <span class="text-gray-700 font-semibold">Gender*</span>
              <div id="gender-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="genderOutput"
                  :choiceList="genderChoice"
                  :hasOther="false"
                  category="gender"
                  @answerChange="setData($event, 'genderOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold">Nationality*</span>
              <div id="nationality-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="nationalityOutput"
                  :choiceList="nationalityChoice"
                  :hasOther="false"
                  category="nationality"
                  @answerChange="setData($event, 'nationalityOutput')"
                />
              </div>
            </div>

            <div class="block" v-if="branchNationalityOutput">
              <span class="text-gray-700 font-semibold"
                >Ethnicity for Malaysian*</span
              >
              <div id="ethnic-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="ethnicityOutput"
                  :choiceList="ethnicityChoice"
                  :hasOther="true"
                  category="ethnicity"
                  @answerChange="setData($event, 'ethnicityOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Country of Residence*
              </span>
              <div id="country-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="countryOutput"
                  :choiceList="countryChoice"
                  :hasOther="true"
                  category="country"
                  @answerChange="setData($event, 'countryOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Is Khazanah Alumni?*
              </span>
              <div id="test">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="kAlumniOutput"
                  :choiceList="kAlumniChoice"
                  :hasOther="false"
                  category="isKAlumni"
                  @answerChange="setData($event, 'kAlumniOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Current Khazanah ND?*
              </span>
              <div id="test">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="currentNDOutput"
                  :choiceList="currentNDChoice"
                  :hasOther="false"
                  category="isKND"
                  @answerChange="setData($event, 'currentNDOutput')"
                />
              </div>
            </div>

            <label class="block" v-if="branchCurrentNDOutput">
              <span class="text-gray-700 font-semibold"
                >Name of Investee Co or SPV on which the individual is KNB's
                ND*</span
              ><br />
              <span>e.g: edotco Group Sdn Bhd</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="ndCompanyOutput"
                @multiValueChange="setData($event, 'ndCompanyOutput')"
              />
            </label>

            <label class="block" v-if="branchCurrentNDOutput">
              <span class="text-gray-700 font-semibold">Asset Class*</span
              ><br />
              <span>e.g: Telco & Media</span>
              <BaseMultiValue
                :formMode="formMode"
                :editData="assetClassOutput"
                @multiValueChange="setData($event, 'assetClassOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Education Level, Discipline</span
              ><br />
              <span>e.g: </span><br />
              <span>MBA; Harvard Business School</span><br />
              <span>
                Bachelor of Arts (Magna Cum Laude) in East Asian Language and
                Civilizations (Japanese) and Economics; Harvard College</span
              >

              <BaseMultiValue
                :formMode="formMode"
                :editData="educationOutput"
                @multiValueChange="setData($event, 'educationOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Professional Qualification</span
              ><br />
              <span>e.g: Association of Chartered Certified Accountants</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="qualificationOutput"
                @multiValueChange="setData($event, 'qualificationOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Professional Membership</span
              ><br />
              <span>e.g: Malaysian Institute of Accountants</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="membershipOutput"
                @multiValueChange="setData($event, 'membershipOutput')"
              />
            </label>
            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Current Position(s)</span
              ><br />
              <span>e.g: </span><br />
              <span>NINE - Non independent non executive director;</span><br />
              <span>INE - Independent non executive director; </span> <br />
              <span>CEO, CIO, CTO, CHRO; </span> <br />
              <span>Advisor; etc.</span> <br />

              <BaseMultiValue2
                :formMode="formMode"
                @multiValueChange="setData($event, 'currentPositionOutput')"
                :isUntilPresent="false"
                :editData="currentPositionOutput"
              />
            </label>
            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Previous Employment, Designation and Company Name*</span
              ><br />
              <span>e.g: </span><br />
              <span
                >2013 – Present: Board member & Board Audit Committee, XYZ
                Group</span
              ><br />
              <span
                >2011 – 2018: Executive Director, Khazanah (Telco, Media, L&T
                and Education); Director & Board
              </span>

              <BaseMultiValue2
                :formMode="formMode"
                @multiValueChange="setData($event, 'previousEmploymentOutput')"
                :isUntilPresent="true"
                :editData="previousEmploymentOutput"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Industries or sectors of previous employments*</span
              ><br />
              <span></span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="previousIndustriesOutput"
                @multiValueChange="setData($event, 'previousIndustriesOutput')"
              />
            </label>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Candidate Skill Set*</span
              >

              <BaseCheckBoxSkillSet
                :formMode="formMode"
                :editDataAnswer="skillOutput"
                :editDataOther="skillOutputOther"
                :hasOther="true"
                :choiceList="skillSetChoice"
                @answerChange="
                  setDataCheckbox($event, ['skillOutput', 'skillOutputOther'])
                "
              />
            </div>

            <label class="block">
              <span class="text-gray-700 font-semibold">Team Comments</span
              ><br />
              <span
                >Example: <br />
                (i) High alignment with Khazanah in driving changes to achieve
                our objectives <br />
                (ii) proven track record and skills in restructuring and legal
                framework <br />
                (iii) Able to draw upon past experience to meet the company's
                business needs (e.g. board audit committee and M&A experience)
                <br />
                (iii) Valuable insights related to industry XYZ</span
              >
              <textarea
                class="form-textarea mt-1 block w-full border-none rounded-lg"
                rows="5"
                placeholder="Comments."
                v-model="comments"
              ></textarea>
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold">LinkedIn URL</span>
              <input
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
                v-model="url"
              />
            </label>

            <!-- attachment  -->

            <div class="-mb-10">
              <label
                for="user_avatar"
                class="bg-firstBlue text-white font-normal px-3 py-1 my-3 rounded-lg flex hover:bg-secondBlue hover:cursor-pointer w-56"
              >
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z"
                    />
                    <path
                      d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z"
                    />
                  </svg>
                  <span class="ml-1">Upload Profile Picture </span>
                </div>
              </label>
              <img
                :src="getPP"
                class="h-14 w-14 rounded-full mx-2 mt-1"
                alt=""
                v-show="getPP != ''"
              />
              <input
                class="mt-1 block h-0 w-full text-sm text-gray-900 rounded-lg border-none cursor-pointer focus:outline-none opacity-0"
                aria-describedby="user_avatar_help"
                id="user_avatar"
                type="file"
                accept=".jpg, .png"
                @change="encodeImageFileAsURL"
                ref="file"
              />
            </div>

            <div class="upload-attachments">
              <label
                for="attachment"
                class="bg-firstBlue text-white font-normal px-3 py-1 my-3 rounded-lg flex hover:bg-secondBlue hover:cursor-pointer w-56"
              >
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <span class="ml-1">Upload PDF </span>
                </div>
              </label>

              <input
                class="mt-1 block h-0 w-full text-sm text-gray-900 rounded-lg border-none cursor-pointer focus:outline-none opacity-0"
                aria-describedby="pdf_attachment"
                id="attachment"
                type="file"
                accept="application/pdf"
                @change="encodeFileAsBlob"
                ref="pdffile"
              />
              <div id="downloadLinkContainer">{{ pdfFileName }}</div>
              <!-- when edit need to be clickable -->
            </div>

            <div
              id="recommendation-check-box"
              class=""
              v-if="formMode == 'Edit'"
            >
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="isRecommended"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z"
                  />
                </svg>

                <span class="ml-2 font-semibold"
                  >Recommended for Executive/Senior Management Positions</span
                >
              </label>
            </div>

            <!-- add approve button  -->
            <div id="approve-check-box" class="" v-if="formMode == 'Edit'">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="reviewed"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="ml-2 font-semibold">Reviewed by IMSS</span>
              </label>
            </div>
            <div id="archived-check-box" class="" v-if="formMode == 'Edit'">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="archived"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    d="M2 3a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1H2z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2 7.5h16l-.811 7.71a2 2 0 01-1.99 1.79H4.802a2 2 0 01-1.99-1.79L2 7.5zm5.22 1.72a.75.75 0 011.06 0L10 10.94l1.72-1.72a.75.75 0 111.06 1.06L11.06 12l1.72 1.72a.75.75 0 11-1.06 1.06L10 13.06l-1.72 1.72a.75.75 0 01-1.06-1.06L8.94 12l-1.72-1.72a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="ml-2 font-semibold">Archived</span>
              </label>
            </div>
            <button
              v-show="!showProcessButton"
              type="submit"
              class="w-full px-6 py-2.5 bg-firstBlue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-secondBlue hover:shadow-lg focus:bg-secondBlue focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondBlue active:shadow-lg transition duration-150 ease-in-out disabled:opacity-50"
              @click="onSubmit"
              :disabled="isBtnDisable"
            >
              Send
            </button>

            <button
              v-show="showProcessButton"
              type="button"
              class="inline-flex items-center place-content-center w-full px-6 py-2.5 bg-firstGray text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
              disabled
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              Processing...
            </button>

            <button
              v-show="showDeleteButton && formMode == 'Edit'"
              type="submit"
              class="w-full px-6 py-2.5 -my-7 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-secondBlue hover:shadow-lg focus:bg-secondBlue focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondBlue active:shadow-lg transition duration-150 ease-in-out disabled:opacity-50"
              @click="onDelete"
              :disabled="isBtnDisable"
            >
              Delete
            </button>

            <button
              v-show="!showDeleteButton && formMode == 'Edit'"
              type="button"
              class="inline-flex items-center place-content-center w-full px-6 py-2.5 -my-7 bg-red-300 text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
              disabled
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              Processing...
            </button>
          </form>
        </div>
      </div>
    </section>

    <div
      v-if="showLoading"
      class="flex flex-col items-center justify-center space-x-2 h-screen"
    >
      <div
        class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="my-1">Loading...</span>
    </div>
  </div>
</template>

<script>
import BaseMultiValue from "./FormComponents/BaseMultiValue.vue";
import BaseMultiValue2 from "./FormComponents/BaseMultiValue2.vue";
import BaseRadioOther from "./FormComponents/BaseRadioOther.vue";
import BaseCheckBoxOther from "./FormComponents/BaseCheckBoxOther.vue";
import BaseCheckBoxSkillSet from "./FormComponents/BaseCheckBoxSkillSet.vue";

import CheckDuplicate from "./FormComponents/CheckDuplicate.vue";


import BaseDropdown from "./FormComponents/BaseDropdown.vue";
import TeamDropdown from "./FormComponents/TeamDropdown.vue";

import computed from "@/utils/form/computed";
import methods from "@/utils/form/method";
import formData from "@/utils/form/data";



export default {
  name: "TheForm",
  data() {
    return formData
  },
  props: {
    formMode: String,
  },
  computed,
  methods,
  components: {
    BaseMultiValue,
    BaseMultiValue2,
    BaseRadioOther,
    BaseCheckBoxOther,
    BaseCheckBoxSkillSet,
    BaseDropdown,
    TeamDropdown,
    CheckDuplicate
  },
  created() {
    this.showLoading = false;
    console.log(this.formMode);
    if (this.formMode === "Edit") {
      console.log("Form Mode is Edit");
      this.$store.dispatch("track/trackEditSubmission");
      this.initializeEditForm();
    } else {
      console.log("Form Mode is Create");
      this.initializeCreateForm();
    }
    
  },
};
</script>
