<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto p-5 bg-white shadow-md rounded-xl" v-if="detailsData!=null">
      <BorderLine :text="'Glance'" />
      <div id="Glance" class="flex flex-col md:flex-row gap-x-12">
        <div id="pic" class="flex justify-left">
          <img
            :src="detailsData.b64img || defaultimg"
            class="h-32 w-32 rounded-full mt-2"
            alt=""
          />
        </div>
        <div id="glanceinfo" class="flex flex-col gap-y-1">
          <div id="namenlinkedin" class="flex flex-row items-center">
            <div
            id="name"
            class="flex justify-left text-left text-2xl font-semibold"
          >
            {{ detailsData.name }}
          </div>
          <div id="linkedin" class="ml-4" v-if="detailsData.url">
            <a
              :href="detailsData.url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-600 hover:underline"
            >
              <img src="../assets/LinkedIn_logo_initials.png" class="h-6 hover:cursor-pointer" >
            </a>
          </div>
          </div>

          <div id="glance-edu">
            <div
            v-for="item in detailsData.education"
            class="flex flex-col"
          >
            <p class="text-sm">{{ item.value }}</p>
          </div>

          </div>
          <div id="glace-others " class="flex flex-wrap gap-y-1 md:flex-row gap-x-3 text-sm">
            <div id="age">
              Age: {{ detailsData.age }}
            </div>
            <div id="honorific">
              Honorific: {{ detailsData.honorific }}
            </div>
            <div id="alumni">
              Khazanah Alumni: {{ detailsData.k_alumni }}
            </div>
            <div id="country">
              Country of Residence: {{ detailsData.residence_country }}
            </div>
            <div id="nationality">
              Nationality: {{ detailsData.nationality }}
            </div>
    

          </div>
          <div id="glance-recommendation" class="text-firstBlue font-semibold">
            <p v-if="detailsData.is_recommended=='Director'">Recommended for: Director</p>
            <p v-if="detailsData.is_recommended=='Executive/ Senior Management'">Recommended for: Executive/ Senior Management</p>
          </div>


        </div>
      </div>
      <BorderLine :text="'Skills'" />
      <div id="skills" class="flex flex-row flex-wrap gap-2">
            <div v-for="item in detailsData.skill" class="my-1">
              <p
                class="bg-kGreen rounded-xl text-white font-semibold py-1 px-4 mx-1 text-sm"
              >
                {{ item.value }}
              </p>
            </div>
          </div>
      <BorderLine :text="'Current Position(s)'" />
      <div id="current position" class="">
          <div
            id="position"
            v-for="item in detailsData.current_position"
            class="flex flex-col text-sm pb-2"
          >
            <p class="px-1">
              <span v-if="item.start">{{ item.start + ": " }}</span
              >{{ item.value }}
            </p>
          </div>
        </div>
      <BorderLine :text="'Industry Experience'" />
      <div class="flex flex-row flex-wrap gap-2">
            <div v-for="item in detailsData.previous_industries" class="">
              <p
                class="bg-white rounded-xl text-firstBlue font-semibold border-2 border-firstBlue  py-1 px-4 mx-1 text-sm"
              >
                {{ item.value }}
              </p>
            </div>
          </div>
      <BorderLine :text="'Previous Employment'" />
      <div
          id="previous employment"
          v-for="item in detailsData.previous_employment"
          class="pb-2 text-sm"
        >
          <p class="">
            <span v-if="item.start">{{ item.start }}</span>
            <span v-if="item.end">{{ " - " + item.end   }}</span>
            <span v-if="item.start">: </span>
            {{ item.value }}
          </p>
        </div>
      <BorderLine v-if="detailsData.membership" :text="'Membership(s)'" />
      <div v-if="detailsData.membership" v-for="item in detailsData.membership" class="flex flex-col text-sm">
        <p class="px-1">{{ item.value }}</p>
      </div>
      <BorderLine v-if="detailsData.qualification" :text="'Qualification(s)'" />
        <div
        v-for="item in detailsData.qualification"
        class="flex flex-col text-sm"
      >
        <p class="px-1">{{ item.value }}</p>
      </div>
      <BorderLine v-if="detailsData.comments" :text="'Comment'" />
      <p class="block text-sm">{{ detailsData.comments }}</p>

      <button
                v-if="detailsData.has_attachment"
                @click="handleDownload"
                class="flex flex-row text-white bg-firstBlue rounded-3xl text-md py-1 px-3 hover:text-secondBlue w-52 items-center mt-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                <span class="w-full">Download Attachment</span>
      </button>

      <div class="italic pt-3 text-sm">Last Edit: {{ detailsData.date }}</div>

      
    </div>
    <div v-for="index in 10" :key="index" class="my-6">
        <BaseSkeletonLoading v-show="showLoading" />
      </div>
  </div>
</template>

<script>
import BorderLine from "../components/BorderLine.vue";
import defaultimgjson from "../assets/defaultimg.json";
import BaseSkeletonLoading from "../components/BaseSkeletonLoading.vue";

export default {
  components: { BorderLine,BaseSkeletonLoading },
  data() {
    return {
      detailsData: null,
      defaultimg: defaultimgjson["imgb64"],
    };
  },
  computed: {
    showLoading() {
      return this.$store.state.data.homeLoading;
    },
  },
  methods:{
    handleDownload() {
      let id = this.detailsData.id;
      let fileName = this.detailsData.name;
      this.$store.dispatch("data/downloadAttachment", [id, fileName]);
    },
    async getDetailsData(){
      const id = this.$route.query.uuid
      await this.$store.dispatch("auth/getAuth");
      await this.$store.dispatch("data/getDataByID", [id,this.$store.state.auth.token]);
     
    }
  },
  async created() {
    await this.getDetailsData()
    this.detailsData = this.$store.state.data.detailsData;
 
  },
};
</script>
