import { APIURL } from "@/utils/authConfig";

export default {
  namespaced: true,
  actions: {
    async trackNewSubmission({rootState}) {
      let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW!
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-newsubmission`, requestOptions);
    },
    async trackEditSubmission({rootState}) {
       let token = rootState.auth.token; // NEW!
        let username = rootState.auth.username; // NEW!
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-editsubmission`, requestOptions);
    },
    async trackRating({rootState},[score, comment]) {
        let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW!
      var body = { score: score, comment: comment };

      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      };

      const res = await fetch(`${APIURL}/track-rating`, requestOptions);
    },
    async trackExportButton({rootState}) {
        let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW!
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-export`, requestOptions);
    },
    async trackSearch( {rootState},searchQuery) {
      console.log("trackSearch",searchQuery);
        let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW!
      var body = { query: searchQuery };
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      };

      const res = await fetch(`${APIURL}/track-search`, requestOptions);
    },
    async trackHome({rootState}) {
      try {
        let token = rootState.auth.token; // NEW!
      let username = rootState.auth.username; // NEW!
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("username", username); // send data to backend
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
        };

        const res = await fetch(`${APIURL}/track-home`, requestOptions);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
