<template>
  <div class="bg-cover  font-roboto">
    <div class="min-h-screen mx-auto py-5">


      <TheForm formMode="Edit"/>

      
    </div>
  </div>
</template>

<script>

import TheForm from '../components/TheForm.vue'


export default {
  
  components: {TheForm },
  data(){
    return{
    
    }
  },
  methods:{

  },
  computed:{


  },
  async created(){


    
  }
}
</script>
