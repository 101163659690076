import { APIURL } from "@/utils/authConfig";

export default {
    namespaced: true,
    state: {
      newsList: [],
      isLoading: true,
      nextOffset: 0,
      latestNews :[]
    },
    mutations: {
      setIsLoading(state, value) {
        state.isLoading = value;
      },
      setNewsList(state, value) {
        if(state.newsList.length ===0){state.newsList = value;}else{
          state.newsList = state.newsList.concat(value);
        }
      },
      setLatestNews(state, value) {
        state.latestNews = value;
      },
      setOffset(state, value) {
        state.nextOffset = value;
      },
    },
    actions: {  
      async getPaginatedData({ commit, dispatch,rootState }, [token, offset, itemSize]) {
        const url = `${APIURL}/get-news-paginated?offset=${offset}&item_size=${itemSize}`;
        try{
            commit("setIsLoading", true);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
            myHeaders.append("accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = { headers: myHeaders,
                                  method: "GET"};
            const res = await fetch(url, requestOptions);
            if (res.status == 401) {
              throw new Error(res.status);
            }
            const rawData = await res.json();
            var data = rawData["items"];
            const nextOffset = rawData["offset"];
            commit("setNewsList", data);
            commit("setOffset", nextOffset);
            commit("setIsLoading", false);

        }catch(error){
          console.warn(error);
        }
   

      },

      async getLatestNews({ commit },token) {
        const url = `${APIURL}/get-latest-news`;
        try {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
          myHeaders.append("accept", "application/json");
          var requestOptions = { headers: myHeaders, method: "GET" };
          const res = await fetch(url, requestOptions);
          if (res.status == 401) {
        throw new Error(res.status);
          }
          const data = await res.json();
          commit("setLatestNews", data);
        } catch (error) {
          console.warn(error);
        }
      }
      }
    }