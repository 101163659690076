<template>
    <div class="chevron-container" :class="{ 'has-left-triangle': hasLeftTriangle }">
      <div class="chevron-button">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ChevronButton",
    props: {
      hasLeftTriangle: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style scoped>
  .chevron-container {
    display: inline-flex;
    position: relative;
    margin-right: -2px; /* To prevent overlapping */
  }
  
  .chevron-button {
    @apply bg-firstBlue text-white font-bold px-6 py-2 text-center;
    clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%);
  }
  
  .chevron-container::after {
    content: '';
    position: absolute;
    top: 0;
    right: -15px; /* Matches the clip-path offset */
    width: 15px;
    height: 100%;
    background-color: #1d4ed8; /* Tailwind color (blue-700) for the chevron edge */
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    z-index: -1;
  }
  
.chevron-container.has-left-triangle::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px; /* Adjust based on the chevron size */
    width: 16px;
    height: 100%;
    background-color: #f9f9f9; /* Background color to create "negative triangle" */
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    z-index: 1;
}
  </style>
  