<template>
  <div class="w-64 xl:w-full ">
    <carousel ref="carouselNews" :autoplay="3500" >
      <slide v-for="news in latestNews" :key="news.id" class="h-40 flex flex-col items-start text-sm justify-start text-left">

            <p>{{ news.pubdate }}</p>
            <a :href="news.link" target="_blank" class="hover:text-secondBlue">{{ news.title }}</a>
  
      
      </slide>
  
      <template #addons>
        <!-- <navigation /> -->
        <pagination />
      </template>
    </carousel>
  </div>
  </template>
  
  <script>
  // If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  import { mapState } from 'vuex';
  import { ref } from 'vue'
  
  export default {
    name: 'NewsCarousel',
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    setup(){
      const carouselNews = ref(null)
      return {carouselNews}
    },
    computed:{
    ...mapState('news', ['latestNews']),

  },
    methods: {
      handleResize() {
        this.carouselNews.restartCarousel()
      },
    },
  mounted(){
    this.carouselNews.restartCarousel()
    window.addEventListener('resize', this.handleResize);

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  }
  </script>

<style scoped>

</style>
  