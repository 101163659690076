<template>
  <div class="bg-white rounded-xl shadow-md flex flex-col text-sm accordion-item dark:hover:bg-gray-800 my-3">
    <button
      class="flex flex-row gap-x-3 hover:bg-gray-100 focus:bg-gray-100 ring-1 ring-gray-200 border-b-0 border-gray-200 active:bg-gray-100 accordion-button collapsed relative items-center w-full py-4 px-5 text-sm text-gray-800 text-left bg-white border-0 rounded-xl transition focus:outline-none"
      type="button"
      v-if="hasContent"
      data-bs-toggle="collapse"
      :data-bs-target="iDTarget"
      aria-expanded="true"
      :aria-controls="id"
    >
      <slot name="icon" class="w-5 h-5"></slot>
      <div>{{ title }}</div>
    </button>
    <div v-if="!hasContent" class="flex flex-row gap-x-3 items-center w-full py-4 px-5 text-sm text-gray-800 bg-white rounded-xl">
        <slot name="icon"></slot>
      <div>{{ title }}</div>
    </div>

    <div v-if="hasContent"
				:id="id" 
				class="accordion-collapse collapse bg-white rounded-xl"
				aria-labelledby="accordionHeader"
			>
				<div class="accordion-body bg-white py-4 px-5 mx-6 rounded-xl">
					<slot name="content"></slot>   
				</div>
			</div>

  </div>
</template>

<script>
export default {
  name: "SelectionProcessCard",
  props: {
    title: String,
    id: String,
    hasContent: {
      type: Boolean,
      default: true
    },
  },
  data() {
		return {
			iDTarget: `#${this.id}`,
		};
	}
};
</script>
