<template>
  <div
    class="bg-white shadow-md flex flex-col justify-between rounded-lg my-3 px-2 h-64"
    v-if="
      this.$store.state.auth.adminRole ||
      (metaDisplay['reviewed'] == 'Reviewed' &&
        metaDisplay['archived'] == 'Live')
    "
  >
    <div>
      <div v-if="isNew" class="bg-firstOrange rounded-xl w-12 px-2 text-firstBlue font-semibold mt-2 -mb-6">
          New
      </div>
      <div id="pic" class="flex justify-center">
        <img
          :src="metaDisplay.b64img || defaultimg"
          class="h-14 w-14 rounded-full mt-2"
          alt=""
        />
      </div>
      <div id="tags and expand" class="px-1 flex justify-center space-x-1">
        <span
          id="reviewed"
          class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-thirdBlue rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
          v-if="
            this.$store.state.auth.adminRole &&
            metaDisplay.reviewed == 'Reviewed'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
            />
          </svg>

          : Reviewed
        </span>

        <span
          id="not-reviewed"
          class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-secondOrange rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
          v-if="
            this.$store.state.auth.adminRole &&
            metaDisplay.reviewed == 'Pending'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
            />
          </svg>

          : Pending
        </span>
        <span
          id="archived"
          class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-secondOrange rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
          v-if="
            this.$store.state.auth.adminRole &&
            metaDisplay.archived == 'Archived'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
            />
          </svg>
          : Archived
        </span>
        <span
          id="not-archived"
          class="w-16 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-thirdBlue rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
          v-if="
            this.$store.state.auth.adminRole && metaDisplay.archived == 'Live'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
            />
          </svg>
          : Live
        </span>

        <button
          id="admin edit"
          class="leading-none pt-1 border-skin-input  text-secondBlue bg-clip-content items-center justify-center font-semibold text-xs hover:scale-125"
          v-if="this.$store.state.auth.adminRole"
          @click="editForm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.0"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>

      <div id="name" class="flex justify-center text-center">
        <div id="nameonly">
          <span
            class="text-md text-black font-semibold"
          >
            {{ metaDisplay.name }}
          </span>
        </div>
      </div>
      <div id="age" class="flex justify-center text-xs">
        Age: {{ metaDisplay.age }}
      </div>
      <div
        id="current-position"
        class="flex flex-col justify-center text-xs items-center"
      >
        <div
          id="position"
          v-for="item in metaDisplay.current_position"
          class="flex py-0.5"
        >
          <p :class="['px-1', isExpand ? '' : 'line-clamp-1']">
            <span v-if="item.start">{{ item.start + ": " }}</span
            >{{ item.value }}
          </p>
        </div>
      </div>
    </div>

    <div id="details-btn" class="flex justify-end my-3 mr-2">
      <button
        class="bg-white rounded shadow px-4  text-black text-sm font-semibold"
        @click="goDetails"
      >
        >>
      </button>
    </div>

  </div>
</template>

<script>
import defaultimgjson from "../assets/defaultimg.json";
export default {
  name: "BaseDataCard",
  props: {
    meta: Object, //this is Object not Array!!!!
    isAdmin: Boolean,
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maxLine: 3,
      isExpand: false,
      needExpand: false,
      defaultimg: defaultimgjson["imgb64"],
    };
  },
  computed: {
    metaDisplay() {
      let tempObj = {};
      Object.entries(this.meta).forEach(([key, val]) => {
        //if val is array then slice it to maxLine
        if (Array.isArray(val)) {
          tempObj[key] = val.slice(0, this.maxLine);
        } else {
          tempObj[key] = val;
        }
      });

      return tempObj;
    },

  },
  methods: {
    editForm() {
      this.$router.push({
        name: "EditView",
        query: {
          uuid: this.meta.id,
        },
      });
    },
    goDetails() {
      this.$router.push({
      name: "DetailsView",
      query: {
          uuid: this.meta.id,
        },
      });
    },
    handleDownload() {
      let id = this.metaDisplay.id;
      let fileName = this.metaDisplay.name;
      this.$store.dispatch("data/downloadAttachment", [id, fileName]);
    },
  },
  mounted() {

  },
  watch: {},
};
</script>
