<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto py-5 flex flex-col gap-y-6">
      <div id="bec-template" class="text-sm flex flex-col">
        <p class="font-semibold">
          Where can I find BEC Meeting ND Proposal Template?
        </p>
        <p>
          You can download the template
          <a
            href="https://khazanahcommy.sharepoint.com/:p:/s/AndromedaWebApp/EeAymPaNSglAkrz2UptxHV0B9Bk8vFJ5xaTLZyBPZh4oAg?e=Xnwi8b"
            target="_blank"
            class="underline hover:cursor-pointer hover:text-secondBlue"
            >here.</a
          >
        </p>
      </div>

      <div id="spv-farmlist" class="text-sm flex flex-col">
        <p class="font-semibold">Where can I find the SPV Farm List?</p>
        <p>
          You can find the SPV Farm List in
          <a
            href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/CSS-Secretarial#spv-related-references"
            target="_blank"
            class="underline hover:cursor-pointer hover:text-secondBlue"
            >MyKhazanah - CSS Secretarial</a
          >.
        </p>
      </div>

      <div id="process" class="flex flex-col">
        <p class="font-semibold text-sm">
          What are the processes in nominating and appointing a Nominee Director
          (“ND”)
        </p>
        <p class="text-sm">Refer to diagram 1</p>

        <div class="text-sm md:mx-12">
          <p class="mt-10 mb-3 ">
            Diagram 1: Processes in nominating and appointing a Nominee Director
            (“ND”)
          </p>

          <div class="w-full flex flex-row gap-x-3">
                <a href="faq#selection" class="hover:cursor-pointer hover:scale-105 transform transition-transform duration-200">
              <img src="@/assets/process1.png" alt="">
              </a>
              <a href="faq#appointment" class="hover:cursor-pointer hover:scale-105 transform transition-transform duration-200">
              <img src="@/assets/process2.png" alt="">
              </a>
              <a href="faq#evaluation" class="hover:cursor-pointer hover:scale-105 transform transition-transform duration-200">
              <img src="@/assets/process3.png" alt="">
              </a>
              <a href="faq#renewal" class="hover:cursor-pointer hover:scale-105 transform transition-transform duration-200">
              <img src="@/assets/process4.png" alt="">
              </a>
            

          </div>


          <p class="mt-10 text-md font-semibold" id="selection">A. Selection</p>
          <SelectionProcessCard
            title="1. Conduct search for ND candidate "
            id="A1"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  Teams may refer to External ND Database and consult with IMSS
                  where necessary
                </li>
                <li>
                  Teams should refer to the Board Composition Matrix to identify
                  relevant skills and backgrounds for the ND candidate
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="2. Conduct background screening (External NDs only) "
            id="A2"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>Contact GRC (<a class="text-firstBlue underline hover:text-secondBlue" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FM%26M%20Manual%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents" target="_blank">M&M Manual 3B.4.2</a>  – page 33)</li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard title="3. Prepare BEC paper  " id="A3">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  All ND Nominations will require a pre-counsel with the Board
                  Effectiveness Council prior to tabling to IC
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard title="4. Prepare IC paper" id="A4">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  Refer template in M&M Manual ( <a class="text-firstBlue underline hover:text-secondBlue" target="_blank" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - I Nomination of ND as at 19 Feb 2024.pdf?web=1">M&M Manual Appendix 3B – I</a>)
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <p class="mt-10 text-md font-semibold" id="appointment">B. Appointment</p>

          <SelectionProcessCard title="1. Submit IC Paper " id="B1">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>Within ND Nomination cycle submit to IMSS</li>
                <li>
                  Outside ND Nomination cycle submit directly through
                  <a
                    class="hover:text-blue-500 underline"
                    target="_blank"
                    href="https://khazanah.dealcloud.com"
                    >DealCloud</a
                  >,to notify IMSS
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard title="2. Obtain approval" id="B2">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>Internal ND – Approval from IC</li>
                <li>
                  External ND – IC recommend for EXCO Approval (SMB & LOA – B9
                  page 19)
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="3. Prepare Letter of Nomination to Investee Company"
            id="B3"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>Refer template at <a class="text-firstBlue underline hover:text-secondBlue" target="_blank" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FMM%20Manual%20%2D%20Appendix%203B%20%2D%20II%20Letter%20to%20Investee%20Company%20on%20Nomination%20of%20ND%20as%20at%2019%20Oct%202022%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents">M&M Manual Appendix 3B – II</a> </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="4. Prepare Letter of Appointment to ND (upon approval from investee company)"
            id="B4"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li><a class="text-firstBlue underline hover:text-secondBlue" target="_blank" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FMM%20Manual%20%2D%20Appendix%203B%20%2D%20III%20ND%20Letter%20of%20Appointment%20as%20at%2019%20Oct%202022%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents">M&M Manual Appendix 3B – III</a> </li>
                <li>Include ND Term Sheet (<a class="text-firstBlue underline hover:text-secondBlue" target="_blank"  href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FMM%20Manual%20%2D%20Appendix%203B%20%2D%20V%5FND%20Term%20Sheet%20as%20at%2019%20Oct%202022%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents">M&M Manual Appendix 3B – V</a>)</li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard title="5. Inform IMSS and Secretarial" id="B5">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  Inform details of ND: name, date of appointment and brief
                  profile
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="6. Maintain documentation in M&M Team’s folder"
            id="B6"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <p class="mt-10 text-md font-semibold" id="evaluation">C. Evaluation</p>

          <SelectionProcessCard title="1. Complete ND Evaluation Form" id="C1">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  To be completed once a year (<a class="text-firstBlue underline hover:text-secondBlue" target="_blank" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FM%26M%20Manual%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents">M&M Manual</a> 3B.4.6 – page 35)
                </li>
                <li>Head of Asset Class signature constitutes approval</li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="2. Submit form to IMSS via email (knb.inv.ops.monitoring@khazanah.com.my)"
            id="C2"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="3. Discuss ND evaluation results and recommendations with Head of Asset Class"
            id="C3"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="4. Confirm action plan moving forward"
            id="C4"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="5. Discuss improvement areas with ND"
            id="C5"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="6. Maintain documentation in M&M Team’s folder"
            id="C6"
            :hasContent="false"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </template>
          </SelectionProcessCard>

          <p class="mt-10 text-md font-semibold" id="renewal">D. Renewal</p>

          <SelectionProcessCard
            title="1. Renewal of External ND (every 3 years)"
            id="D1"
          >
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  Complete ND Evaluation Form (<a class="text-firstBlue underline hover:text-secondBlue" target="_blank" href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents%2FM%26M%20Manual%2Epdf&parent=%2Fsites%2FMyKhazanah%2FINV%2FShared%20Documents">M&M Manual</a> 3B.5) -Head of Asset
                  Class signature constitutes approval
                </li>
                <li>Conduct background screening</li>
                <li>
                  Prepare Letter of Re-appointment to ND and ND Term Sheet (M&M
                  Manual Appendix 3B – III)
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard title="2. Inform IMSS and Secretarial" id="D2">
            <template v-slot:icon> <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                />
              </svg> </template>

            <template v-slot:content>
              <ul class="text-sm list-disc">
                <li>
                  Inform details of ND: name, date of appointment and brief
                  profile
                </li>
              </ul>
            </template>
          </SelectionProcessCard>

          <SelectionProcessCard
            title="3. Maintain documentation in M&M Team’s folder"
            id="D3"
            :hasContent="false"
          >
            <template v-slot:icon> <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg> </template>
          </SelectionProcessCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectionProcessCard from "@/components/SelectionProcessCard.vue";
import ChevronButton from "@/components/ChevronButton.vue";

export default {
  components: { SelectionProcessCard, ChevronButton },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  async created() {},
};
</script>
