import { APIURL } from "@/utils/authConfig";
import { v4 as uuidv4 } from "uuid";

export default {
  namespaced: true,
  state: {
    lndList: [{
      "id": "14bb44fe-bf4a-4b80-9304-9ef0024b767e",
      "day": 4,
      "month": 9,
      "year": 2024,
      "topic": "Sustainability Driving Future Growth Strategies",
      "presenter": "Margie Ong",
      "title": "Andromeda L&D Training x Thoughts In Gears",
      "pk": "pk"
  }], //dummy data
    isLoading: true,
  },
  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setLNDList(state, value) {
      state.lndList = value;
    },
  },
  actions: {
    async getData({ commit, state}, token) {
      try {
        commit("setIsLoading", true);
        const url = `${APIURL}/lnd`;
        const myHeaders = new Headers();

        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const res = await fetch(url, requestOptions);
        if (res.status == 401) {
          throw new Error(res.status);
        }

        const rawData = await res.json();
        console.log("LND", rawData);
        commit("setLNDList", rawData);
        commit("setIsLoading", false);
      } catch (error) {
        console.warn(error);
        // Should do something here
      }
    },
    async addData({ commit, state }, [data, token]) {
      try {
        // add uuid
        let id = uuidv4();
        data.id = id;
        data.pk = "pk";

        // add to vuex
        commit("setLNDList", [...state.lndList, data]);

        // add to database

        const url = `${APIURL}/lnd`;
        const myHeaders = new Headers();

        var body = JSON.stringify(data);

        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          body: body,
          headers: myHeaders,
        };

        const res = await fetch(url, requestOptions);
        if (res.status == 401) {
          throw new Error(res.status);
        }

        const rawData = await res.json();
        console.log("data added", rawData);
      } catch (error) {
        console.warn(error);
        // Should do something here
      }
    },
    async updateData({ commit, state }, [data, token]) {
      try {
        // add to vuex
        let tempArray = state.lndList;
        let index = tempArray.findIndex((x) => x.id === data.id);
        tempArray[index] = data;
        commit("setLNDList", tempArray);

        // add to database

        const url = `${APIURL}/lnd/${data.id}`;
        const myHeaders = new Headers();

        var body = JSON.stringify(data);

        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "PUT",
          body: body,
          headers: myHeaders,
        };

        const res = await fetch(url, requestOptions);
        if (res.status == 401) {
          throw new Error(res.status);
        }

        const rawData = await res.json();
        console.log("data updated", rawData);
      } catch (error) {
        console.warn(error);
        // Should do something here
      }
    },
    async deleteData({ commit, state }, [id, token]) {
      try {
        // add to vuex
        let tempArray = state.lndList;
        let index = tempArray.findIndex((x) => x.id === id);
        tempArray.splice(index, 1);
        commit("setLNDList", tempArray);

        // add to database

        const url = `${APIURL}/lnd/${id}`;
        const myHeaders = new Headers();

        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "DELETE",
          headers: myHeaders,
        };

        const res = await fetch(url, requestOptions);
        if (res.status == 401) {
          throw new Error(res.status);
        }

        const rawData = await res.json();
        console.log("data deleted", rawData);
      } catch (error) {
        console.warn(error);
        // Should do something here
      }
    },
  },
};
