import { orderBy } from "lodash-es";
import { APIURL } from "@/utils/authConfig";
import { value } from "lodash-es";

export default {
  namespaced: true,
  state: {
    filterArray: [],
    sortMethod: [
      { id: "name", label: "Name", checked: false, order: "asc"},
      { id: "date", label: "Upload Date", checked: false, order: "desc" },
      { id: "age", label: "Age", checked: false, order: "asc" },
      {
        id: "@search.score",
        label: "Relevancy",
        checked: false,
        order: "desc"
      },
    ],
    sortValue: "",
    selectedAuthor:"",
    selectedIvTeam: "",
    showModal:false
  },
  mutations: {
    setShowModal(state,value){
      state.showModal = value},
    setFilterArray(state, value) {
      state.filterArray = value;
    },
    setFilterItem(state, id) {
      state.filterArray[id].checked = !state.filterArray[id].checked;
    },
    clearFilterItem(state, id) {
      state.filterArray[id].checked = false;
    },
    clearSortItem(state, id) {
      state.sortMethod[id].checked = false;
    },
    setSortMethod(state, id) {
      state.sortMethod.forEach((el, index) => {
        //reset ...
        state.sortMethod[index].checked = false;
      });
      state.sortMethod[id].checked = true;
    },
    setSortValue(state, value) {
      state.sortValue = value;
    },
    setSelectedAuthor(state, author) {
      state.selectedAuthor = author;
    },
    setSelectedIvTeam(state, team) {
      state.selectedIvTeam = team;
    },
  },
  actions: {
    async getFilterArray({ commit }, token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      var requestOptions = { headers: myHeaders };

      const res = await fetch(
        `${APIURL}/get-filters`,
        requestOptions
      );
      // console.log(res.status);
      if (res.status == 401) {
        throw new Error(res.status);
      }

      const filter = await res.json();
      console.log("this is new filter",filter)
      commit("setFilterArray", filter);
    },
    resetFilter({ commit, dispatch, state, rootState }) {
      state.filterArray.forEach((el, index) => {
        commit("clearFilterItem", index);
      });
    },
  },
  getters: {
    isAnyFilterChecked(state) {
      return state.filterArray.some(filter => filter.checked);
    }
  }
};
