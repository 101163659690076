export default {
    reviewed: "",
    isRecommended: "",
    ivTeam: "",
    id: "",
    candidateName: "",
    birthYear: "",
    comments: "",
    url: "",
    honorificOutput: "",
    genderOutput: "",
    nationalityOutput: "",
    ethnicityOutput: "",
    countryOutput: "",
    currentNDOutput: "",
    kAlumniOutput: "",
    ndCompanyOutput: [],
    assetClassOutput: [],
    educationOutput: [],
    qualificationOutput: [], //
    membershipOutput: [],
    currentPositionOutput: [], //
    previousEmploymentOutput: [],
    previousIndustriesOutput: [],
    skillOutput: [], //checkbox
    skillOutputOther: [], //checkbox should be list then combine with the item above but id need to be unique???
    b64img: "",
    pdfFile: "",
    pdfFileName: "",
    honorificChoice: ["Mr.", "Ms.", "Dato'", "Datuk", "Dato' Sri", "Tan Sri"],
    genderChoice: ["Male", "Female"],
    nationalityChoice: ["Malaysian", "Non-Malaysian"],
    ethnicityChoice: ["Malay", "Chinese", "Indian"],
    countryChoice: ["Malaysia", "UK or EU"],
    currentNDChoice: ["Yes", "No"],
    kAlumniChoice: ["Yes", "No"],
    skillSetChoice: [
      "Restructuring",
      "Financial Management",
      "Sales and Marketing",
      "Legal",
      "Sustainability / ESG",
      "Risk Management",
      "Human Resources",
      "Accounting",
      "Operations",
      "Digital and Technology",
      "Real Estate",
    ],
    teamChoice: [
      "Telco & Media",
      "Infrastructure & Airlines",
      "TM",
      "Telco & Media",
      "TdC",
      "Energy",
      "Investment Support – HRBP",
      "Investment Support – Compliance",
      "Energy, Iskandar & L&T",
      "Private Markets & Technology",
      "Public Markets",
      "Dana Impak & Healthcare",
      "Investment Support - Asset Allocation",
      "Investment Support – IMSS",
      "Iskandar & CAM",
      "Active Opportunities Portfolio",
      "Private – SEA",
      "IHH & ReGen",
      "Leisure & Tourism",
      "Sentinel",
      "Consumer",
      "Healthcare",
      "Technology",
      "Airlines",
      "FIG",
      "Real Asset",
      "Private Fund",
      "Dana Impak",
      "Infrastructure",
      "Public - HQ KL",
    ],

    error: [],
    archived: "",
    hasAttachment: false,
    showLoading: false,
    showProcessButton: false,
    showDeleteButton: true,
    isBtnDisable: false,
    emailAuthor: "",
    emailEditor: "",
    createdDate: "",

}