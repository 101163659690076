<template>
  <div class="pt-3">
    <span class="font-semibold"></span>
    <div
      class="flex text-xs justify-between md:text-md gap-x-2 gap-y-2  md:gap-x-5 md:gap-y-5 text-black flex-row flex-wrap"
    >
      <div class="">
        Gender
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'gender'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div>
        Ethnicity
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'ethnicity'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div>
        Nationality
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'nationality'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div>
        Khazanah Alumni
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'k_alumni'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="k-nd">
        Khazanah ND
        <div v-for="filter in filterArray">
          <label class="inline-flex items-center" v-if="filter.type == 'k_nd'">
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="">
        Age
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'ageRange'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div class="">
        Sort by
        <div v-for="choice in sortChoice">
          <label class="inline-flex items-center">
            <input
              type="radio"
              class="form-radio"
              name="sortRadio"
              :value="choice.label"
              @input="updateSort($event, choice.id)"
            />
            <span class="ml-2">{{ choice.label }}</span>
          </label>
        </div>
      </div>
      <div class="recommended">
        Recommended Positions
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'recommended'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="" v-show="this.$store.state.auth.adminRole">
        Reviewed [Admin View]
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'reviewed'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="" v-show="this.$store.state.auth.adminRole">
        Archived [Admin View]
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'archived'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              :checked="isFilterSelected(filter.id)"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="" v-show="this.$store.state.auth.adminRole">
        Submitted By [Admin View]
        <div class="flex flex-col">
          <div class="flex flex-row items-center">
            <span>Email:</span>
            <select
              name="dropdown"
              v-model="selectedAuthor"
              class="block w-full mt-1 mx-1 border-black rounded-lg"
              @change="updateDropDownAuthor($event, selectedAuthor)"
            >
              <option value="" default selected class=""></option>
              <option v-for="filter in authorList" :value="filter">
                {{ filter.label }}
              </option>
            </select>
          </div>
          <div class="flex flex-row items-center">
            <span>Team:</span>
            <select
              name="dropdown"
              v-model="selectedIvTeam"
              class="block w-full mt-1 mx-1 border-black rounded-lg"
              @change="updateDropDownTeam($event, selectedIvTeam)"
            >
              <option value="" default selected class=""></option>
              <option v-for="filter in ivTeamList" :value="filter">
                {{ filter.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- need to disable if no filter  -->
    <button
      class="bg-firstBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-3"
      @click="applyFilter"
    >
      Apply Filter
    </button>
  </div>
</template>
<script>
export default {
  name: "BaseFilterCategory",
  props: {},
  data() {
    return {
      selectedAuthor: this.$store.state.filter.selectedAuthor || "",
      selectedIvTeam: this.$store.state.filter.selectedIvTeam || "",
    };
  },
  computed: {
    filterArray() {
      return this.$store.state.filter.filterArray;
    },
    authorList() {
      return this.filterArray.filter((el) => {
        return el.type == "author";
      });
    },
    ivTeamList() {
      return this.filterArray.filter((el) => {
        return el.type == "iv_team";
      });
    },
    sortChoice() {
      if (this.$route.name == "SearchView") {
        return this.$store.state.filter.sortMethod;
      } else {
        return this.$store.state.filter.sortMethod.filter((x) => {
          return x.id != "@search.score";
        });
      }
    },
    isFilterSelected() {
      return (id) => {
        return this.$store.state.filter.filterArray.some((filter) => filter.id === id && filter.checked);
      };
    },
  },
  methods: {
    async applyFilter() {
      await this.$store.dispatch("auth/getAuth");
      // check what page is this. if we in home page do diff when we r in search page
      if (this.$route.name == "SearchView") {
        await this.$store.dispatch(
          "search/getSearchResult",
          this.$route.query.query
        );
      } else {
        this.$store.commit("data/resetNDList");
        let adminRole = this.$store.state.auth.adminRole;
        if (adminRole) {
          //getAllData
          await this.$store.dispatch(
            "data/getAllData",
            this.$store.state.auth.token
          );
        } else {
          await this.$store.dispatch("data/getPaginatedData", [
            this.$store.state.auth.token,
            0,
            20,
          ]);
          await this.$store.dispatch("data/getLatest4Data", [
          this.$store.state.auth.token,
      ]);


        }
      }
      if (this.$store.state.filter.filterArray.length == 0) {
        await this.$store.dispatch(
          "filter/getFilterArray",
          this.$store.state.auth.token
        );
      }
    },
    updateCheckBox(event, id) {
      this.$store.commit(
        "filter/setFilterItem",
        this.$store.state.filter.filterArray.findIndex((obj) => obj.id == id)
      );
    },
    updateDropDownAuthor(event, item) {
      //clear all filter
      this.authorList.forEach((el) => {
        this.$store.commit(
          "filter/clearFilterItem",
          this.$store.state.filter.filterArray.findIndex(
            (obj) => obj.id == el.id
          )
        );
      });
      //then select new one
      console.log(item.id);
      this.$store.commit(
        "filter/setFilterItem",
        this.$store.state.filter.filterArray.findIndex(
          (obj) => obj.id == item.id
        )
      );
      this.$store.commit("filter/setSelectedAuthor", item);
      
    },
    updateDropDownTeam(event, item) {
      //clear all filter
      this.ivTeamList.forEach((el) => {
        this.$store.commit(
          "filter/clearFilterItem",
          this.$store.state.filter.filterArray.findIndex(
            (obj) => obj.id == el.id
          )
        );
      });
      //then select new one
      console.log(item.id);
      this.$store.commit(
        "filter/setFilterItem",
        this.$store.state.filter.filterArray.findIndex(
          (obj) => obj.id == item.id
        )
      );
      this.$store.commit("filter/setSelectedIvTeam", item);
    },
    updateSort(event, id) {
      this.$store.commit(
        "filter/setSortMethod",
        this.$store.state.filter.sortMethod.findIndex((obj) => obj.id == id)
      );

      this.$store.commit("filter/setSortValue", id);
    },
  },
  mounted() {
    if (this.$route.name == "SearchView") {
      //need to set default is relevancy
      this.updateSort(null, "@search.score");
    } 
    this.selectedAuthor = this.$store.state.filter.selectedAuthor || "";
    this.selectedIvTeam = this.$store.state.filter.selectedIvTeam || "";
  },
};
</script>
