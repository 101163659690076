<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto py-5">
		<div id="search-filter-row" class="flex flex-row justify-between items-center">
        <TheSearchBar class="w-64" />
      <button
        id="open-filter-pane"
        class="flex flex-row mobileonly:transition mobileonly:duration-300 mobileonly:ease-in-out bg-firstBlue px-3 py-1 rounded-3xl"
        type="button"
        @click="this.$store.commit('filter/setShowModal',true)"
  
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="white"
          class="w-6 h-6 fill-white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
          />
        </svg>

        <h4 class=" text-white text-md hover:text-secondBlue">
          Filters
        </h4>
      </button>
      </div>
      <div
        id="filterPane"
        class="collapse col-span-12 pr-5 mobileonly:collapse md:col-span-3"
      >
      <FilterModal />
      </div>
      <div v-for="index in 10" :key="index">
        <BaseSkeletonLoading v-show="showLoading" />
      </div>

      <div>Search Result for: {{ this.$route.query.query }}</div>

      <TheData :isAdmin="true" :isShowTable="isShowTable" view="search" />
    </div>
  </div>
</template>

<script>
import TheSearchBar from "../components/TheSearchBar.vue";
import BaseSkeletonLoading from "../components/BaseSkeletonLoading.vue";
import TheData from "../components/TheData.vue";
import FilterModal from "../components/FilterModal.vue";


export default {
  components: {
    TheSearchBar,
    BaseSkeletonLoading,
    TheData,
    FilterModal
  },
  data() {
    return {
      isShowTable: false,
    };
  },
  methods: {
    setToggleTable() {
      this.isShowTable = !this.isShowTable;
    },
  },
  computed: {
    showLoading() {
      return this.$store.state.search.searchLoading;
    },
    viewQueryRoute() {
      if (this.$route.name == "SearchView") {
        return this.$route.query;
      }
    },
  },
  async created() {
    // this.$route.query.query
    await this.$store.dispatch("auth/getAuth");
    await this.$store.dispatch(
      "search/getSearchResult",
      this.$route.query.query
    ); //should get from url params
  },
  watch: {
    viewQueryRoute() {
      // console.log("inside view Query Route watch",this.$route.query)
      this.searchQuery = this.$route.query.query; //if someone direct enter
      this.$store.dispatch("search/getSearchResult", this.$route.query.query);
    },
  },
};
</script>
