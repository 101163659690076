<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto">
      <BorderLine text="Glance"/>
      <div class="grid grid-cols-1 grid-rows-3 gap-y-5 md:grid-cols-3 md:grid-rows-1 md:gap-x-5 mb-6">
        <div id="glance-last5additions" class="bg-white shadow-md rounded-2xl p-3 flex flex-col text-sm items-start" >
            <p class="font-semibold text-sm">Latest 4 Additions</p>
            <div v-for="item in latest4"  :key="item.id" class="flex">
              • {{ item.name }}
            </div>

        </div>
        <div id="latest-news" class="bg-white shadow-md rounded-2xl p-3 flex flex-col text-sm items-start w-full justify-between">
          <p class="font-semibold text-sm">Latest News</p>
   
            <NewsCarousel  />
 
          <div class="w-full flex flex-row justify-end">
            <router-link
            to="/news"
          >
            See All &gt;&gt;</router-link>
          </div>

        </div>
        <div id="lnd-training" class="bg-white shadow-md rounded-2xl p-3 flex flex-col text-sm items-start justify-between">
          <p class="font-semibold text-sm">L&D Training</p>
          <div>
            <LnDCarousel />
          </div>
          <div class="w-full flex flex-row justify-end">
            <router-link
            to="/lnd"
          >
            See All &gt;&gt;</router-link>
          </div>
        </div>
        

      </div>
      <BorderLine text="All Data"/>
      <div id="search-filter-row" class="flex flex-row justify-between items-center">
        <TheSearchBar class="w-64" />
      <button
        id="open-filter-pane"
        class="flex flex-row mobileonly:transition mobileonly:duration-300 mobileonly:ease-in-out bg-firstBlue px-3 py-1 rounded-3xl"
        type="button"
        @click="openFilterModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="white"
          class="w-6 h-6 fill-white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
          />
        </svg>

        <h4 class=" text-white text-md hover:text-secondBlue">
          Filters
        </h4>
      </button>
      <div
        id="filterPane"
        class="collapse col-span-12 pr-5  md:col-span-3"
      >
        <FilterModal />
      </div>
      </div>




      <TheData :isAdmin="true" :isShowTable="isShowTable" view="home" />
      <div v-for="index in 10" :key="index">
        <BaseSkeletonLoading v-show="showLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import TheSearchBar from "../components/TheSearchBar.vue";
import BaseSkeletonLoading from "../components/BaseSkeletonLoading.vue";
import TheData from "../components/TheData.vue";
import BaseLogo from "../components/BaseLogo.vue";
import BorderLine from "@/components/BorderLine.vue";
import NewsCarousel from "@/components/NewsCarousel.vue";
import LnDCarousel from "@/components/LnDCarousel.vue";
import FilterModal from "@/components/FilterModal.vue";

export default {
  components: {
    TheSearchBar,
    BaseSkeletonLoading,
    TheData,
    BaseLogo,
    BorderLine,
    NewsCarousel,
    LnDCarousel,
    FilterModal
  },
  data() {
    return {
      isShowTable: false,
      pdfFile: "",
      debounceTimeout: null,
    };
  },
  methods: {
    openFilterModal(){
      this.$store.commit("filter/setShowModal", true);
    },
    setToggleTable() {
      this.isShowTable = !this.isShowTable;
    },
    async doGetPaginatedData(offset) {
      await this.$store.dispatch("auth/getAuth");
      await this.$store.dispatch("data/getPaginatedData", [
        this.$store.state.auth.token,
        offset,
        20,
        this.$store.state.auth.adminRole,
      ]);
    },

    handleScroll() {
      if (
        !(this.$store.state.auth.adminRole || this.$store.state.data.homeLoading || this.$store.state.data.nextOffset==-1) // one more condition is that ad lagi ke records nk load
      ) {
        const bottomOfWindow =
          window.innerHeight + window.scrollY >=
          document.documentElement.offsetHeight - 10;

        if (bottomOfWindow && !this.$store.state.data.homeLoading && this.isHomePage ) {
          this.doGetPaginatedData(this.$store.state.data.nextOffset, 4);
        }
      }
    },
    debounce(func, wait) {
      return (...args) => {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
  },
  computed: {
    showLoading() {
      return this.$store.state.data.homeLoading;
    },
    latest4(){
      return this.$store.state.data.last4NDListGlance;
    },
    latestNews(){
      return this.$store.state.news.latestNews;
    },
    isHomePage(){
      return this.$route.name==="home";
    }
  },

  mounted() {
    window.addEventListener("scroll", this.debounce(this.handleScroll, 200));
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.debounce(this.handleScroll, 200));
  },
};
</script>

<style>
/*@apply bg-white text-blue-400 rounded-full;*/
.active {
  background: white;
  border-radius: 9999px;
  color: #63b3ed;
}
</style>
