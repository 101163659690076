import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import EditView from "../views/EditView.vue";
import SubmissionView from "../views/SubmissionView.vue";
import SearchView from "../views/SearchView.vue";
import FAQView from "../views/FAQView.vue";
import ICDMView from "../views/ICDMView.vue";
import LnDView from "../views/LnDView.vue";
import NewsView from "../views/NewsView.vue";
import RegulatoryView from "../views/RegulatoryView.vue";
import DetailsView from "../views/DetailsView.vue";
import IIC30PercentView from "@/views/IIC30PercentView.vue";
import MyGuidelineView from "@/views/MyGuidelineView.vue";



const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
   { path: "/code=:pathMatch(.*)*", name: "others", redirect: "/" },
  
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
  },
  {
    path: "/edit",
    name: "EditView",
    component: EditView,
  },
  {
    path: "/submission",
    name: "SubmissionView",
    component: SubmissionView,
  },
  {
    path: "/faq",
    name: "FAQView",
    component: FAQView,
  },
  {
    path: "/icdm",
    name: "ICDMView",
    component: ICDMView,
  },
  {
    path: "/lnd",
    name: "LnDView",
    component: LnDView,
  },
  {
    path: "/news",
    name: "NewsView",
    component: NewsView,
  },
  {
    path: "/regulatory",
    name: "RegulatoryView",
    component: RegulatoryView,
  },
  {
    path: "/myguideline",
    name: "MyGuidelineView",
    component: MyGuidelineView,
  },
  {
    path: "/details",
    name: "DetailsView",
    component: DetailsView,
  },
  {
    path: "/iic30percent",
    name: "IIC30PercentView",
    component: IIC30PercentView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { top: 0 }
  }
});

// router.beforeEach((to,from)=>{
//   // console.log(from)
//   store.commit("setPrevLink",from.fullPath)
// })

export default router;
