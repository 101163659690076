<template>
  <div id="header" class="flex flex-row justify-between items-center mt-3">
    <div
      id="topleft-header"
      class="text-xl md:text-3xl font-semibold text-black w-3/4 flex flex-row gap-x-2"
    >
      <button class="block md:hidden" @click="this.$store.dispatch('data/toggleMenu')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <div>
        {{ viewName }}
      </div>
    </div>
    <div
      id="topright-header"
      class="flex flex-row justify-end items-center w-full gap-3"
    >
      <div v-show="viewName!='Submission'" class="text-white bg-firstBlue rounded-3xl text-md py-1 px-3">
        <router-link
          class="hover:text-secondBlue flex flex-row w-full items-start"
          to="/submission"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          <span class="w-full">New Submission</span>
        </router-link>
      </div>

      <div class="hidden md:block">
        <TheHeaderPP id="right-header" class="my-auto" />
      </div>
    </div>
  </div>
 
</template>

<script>
import BaseKLogo from "./BaseKLogo.vue";
import TheHeaderPP from "./TheHeaderPP.vue";
import BaseLogo from "./BaseLogo.vue";

export default {
  name: "TheHeader",
  data(){
    return{
      showMenu:false
    }
  },
  method:{
    toggleMenu(){
      this.showMenu = !this.showMenu
    }
  },
  computed: {
    viewName() {
      let view = this.$route.name;
      console.log(">>>>>view ", view);
      if (view == "home") {
        return "Home";
      } else if (view == "SearchView") {
        return "Search";
      } else if (view == "SubmissionView") {
        return "Submission";
      } else if (view == "EditView") {
        return "Edit";
      } else if (view == "DetailsView") {
        return "Details";
      } else if (view == "FAQView") {
        return "Processes and Templates";
      } else if (view == "RegulatoryView") {
        return "Khazanah Guidelines";
      } else if (view == "ICDMView") {
        return "Access to Institute of Corporate Directors Malaysia (ICDM)";
      } else if (view == "NewsView") {
        return "News";
      } else if (view == "LnDView") {
        return "Learning and Development";
      } else if (view == "IIC30PercentView") {
        return "IIC and 30% Club";
      }else if (view == "MyGuidelineView") {
        return "Regulatory Guidelines";
      }
    },
    otherView() {
      if (this.$route.name == "home") {
        return false;
      } else return true;
    },
  },
  components: { TheHeaderPP, BaseKLogo, BaseLogo },
};
</script>
<style scoped>
a.router-link-exact-active {
  font-weight: bold;
}
</style>
