<template>
  <div class="bg-cover font-roboto">
    <div class="min-h-screen mx-auto py-5">
      <div class="text-sm italic">
        All files are located in
      <a
        href="https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV"
        target="_blank"
        class="hover:cursor-pointer hover:text-secondBlue underline"
        >MyKhazanah</a
      >
      </div>

      <div class="pt-6">

        <div class="grid grid-cols-1 w-full gap-y-6">
          <div
            v-for="(regulatory, index) in regulatoryList"
            :key="index"
            class="bg-white shadow-md rounded-lg p-4 "
          >
     
            <a
              :href="regulatory.link"
              target="_blank"
              class="hover:cursor-pointer hover:text-secondBlue flex flex-row items-center"
            >
            <img v-if="regulatory.type==='pdf'" src="@/assets/pdflogo.png" class="w-6 h-6 mr-3" alt="">
            <img v-if="regulatory.type==='docx'" src="@/assets/docx_icon.png" class="w-6 h-6 mr-3" alt="">
              <p class="font-semibold">{{ regulatory.title }}</p>
            </a>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      regulatoryList: [
        {
          title: "Management & Monitoring (M&M) Manual",
          link :"https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/M&M Manual.pdf?web=1",
          type: "pdf"
        },
        {
          title: "MM Manual - Appendices (Chapter 3B - I_Nomination of ND)",
          link : "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - I Nomination of ND as at 19 Feb 2024.pdf?web=1",
          type : "pdf"
        },
        {
          title : "MM Manual - Appendices (Chapter 3B - II_Letter to Investee Company on Nomination of ND)",
          link : "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - II Letter to Investee Company on Nomination of ND as at 19 Oct 2022.pdf?web=1",
          type: "pdf"
        },
        {
          title : "MM Manual - Appendices (Chapter 3B - III_ND Letter of Appointment)",
          link: "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - III ND Letter of Appointment as at 19 Oct 2022.pdf?web=1",
          type: "pdf"
        },
        {
          title : "MM Manual - Appendices (Chapter 3B - IV_ND Letter of Re-Appointment)",
          link : "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - IV ND Letter of Re-Appointment as at 19 Oct 2022.pdf?web=1",
          type: "pdf"
        },
        {
          title: "MM Manual - Appendices (Chapter 3B - V_ND Term Sheet)",
          link : "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - V_ND Term Sheet as at 19 Oct 2022.pdf?web=1",
          type: "pdf"
        },
        {
          title: "MM Manual - Appendices (Chapter 3B - VI_ND Evaluation Form)",
          link: "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/MM Manual - Appendix 3B - VI ND Evaluation Form.docx?web=1",
          type: "docx"
        },
        {
          title: "Shareholder Expectations and Investment Stewardship 2024 (Including Voting Guideline)",
          link: "https://khazanahcommy.sharepoint.com/sites/MyKhazanah/INV/Shared Documents/Shareholder Expectations and Investment Stewardship 2024.pdf?web=1",
          type: "pdf"
        }

      ],
    };
  },
  methods: {},
  computed: {},
  async created() {},
};
</script>
