// src/store/modules/auth.js
import { myMSALObj, SCOPES } from "@/utils/authConfig";

export default {
  namespaced: true,
  state: {
    username: "",
    token: "",
    authenticated: false,
    view: "View.User",
    adminRole: false,
  },
  mutations: {
    setUsername(state, value) {
      state.username = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setView(state, value) {
      state.view = value;
    },
    setAdminRole(state, value) {
        state.adminRole = value;
      },
  },
  actions: {
    async getAuth({ commit }) {
      await myMSALObj.handleRedirectPromise();
      var accounts = myMSALObj.getAllAccounts();
      if (accounts.length === 0) {
        // No user signed in
        await myMSALObj.loginRedirect();
      }
      //find username
      await myMSALObj
        .acquireTokenSilent({
          account: myMSALObj.getAccountByUsername(accounts[0].username),
          scopes: [SCOPES],
        })
        .then((response) => {
          commit("setToken", response.idToken);
          commit("setUsername", accounts[0].username);
          commit("setAuthenticated", true);
          if (response.account.idTokenClaims.roles.includes("View.Admin")) {
            commit("setView", "View.Admin");
          } else {
            commit("setView", "View.User");
          }
        });
    },
    async getToken({ commit }, username) {
      const res = await myMSALObj.acquireTokenSilent({
        account: myMSALObj.getAccountByUsername(username),
        scopes: [SCOPES],
      });
      const data = res.idToken; //pass idtoken
      const roleArray = res.account.idTokenClaims.roles;
      // check if exist View.Admin , else View.User
      if (roleArray.includes("View.Admin")) {
        commit("setView", "View.Admin");
      } else {
        commit("setView", "View.User");
      }
      commit("setToken", data);
    },
  },
  getters: {
   
  },
};
